import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Faq extends Component {

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h4>General FAQs</h4>
            <div>
              <a
                href="http://www.healthvermont.gov/sites/default/files/documents/pdf/ENV_HS_LEAD_lead-testing-faq.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQs: Testing for Lead in School and Child Care Drinking Water
              </a>
            </div>
            <br />
            <h4>FAQs for the Lead Results Website</h4>
            <br />
            <h5>
              1. Why are no results available for my school or child care?
            </h5>
            <div>
              If you do not see results for a school or child care program, that
              means they have not yet collected samples or received their
              results. A list of child cares that have been scheduled for
              testing can be found{" "}
              <a
                href="https://www.healthvermont.gov/sites/default/files/documents/pdf/ENV_HS_LEAD_child-care-testing-schedule.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              . A list of schools that have been scheduled for testing can be
              found{" "}
              <a
                href="https://www.healthvermont.gov/sites/default/files/documents/pdf/ENV_HS_LEAD_school-testing-schedule.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </div>
            <br />
            <h5>
              2. If a school or child care program closes, can I still see results?
            </h5>
            <div>
              Yes, if a school or child care program is no longer in operations, its results can still be found.<br />
              For information on which child cares and schools are currently in operation, visit the{" "}
              <a
                href="http://www.brightfutures.dcf.state.vt.us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bright Futures Child Care Information System
              </a>{" "}or a{" "}
              <a
                href="https://education.vermont.gov/vermont-schools/school-operations#school-directories"
                target="_blank"
                rel="noopener noreferrer"
              >
                directory of Vermont schools
              </a>
              .
            </div>
            <br />
            <h5>3. Why are there two “dates analyzed” listed for some taps?</h5>
            <div>
              If two dates are listed, it means the first draw and flush samples
              for a given tap were not analyzed by the lab on the same day. The
              first date is when the first draw sample was analyzed, and the
              second date is when the flush sample was analyzed.
            </div>
            <br />
            <h5>
              4. Why was there a delay between “date sampled” and “date
              analyzed”?
            </h5>
            <div>
              “Date sampled” is when the water samples were collected by the
              school or child care provider. “Date analyzed” is when the lab
              tested the sample and recorded the result. Water samples are
              analyzed by the lab in the order they are received. The time
              between when samples are received and when results are sent to
              schools and child cares can vary, but is typically between two to
              six weeks. The time between sample collection and sample analysis
              does not impact the result.
            </div>
            <br />
            <h5>
              5. Why was there a delay between “date analyzed” and “date action
              taken”?
            </h5>
            <div>
              “Date analyzed” is when the lab tested the sample and got a
              result. “Date action taken” is when the school or child care
              provider completed a remediation action. After the samples were
              analyzed, the lab prepares a report and sends it to the school or
              child care provider. The time between when samples are received
              and when results are sent to schools and child cares can vary, but
              is typically between two to six weeks. The school or child care
              provider then determines the best remediation action and takes
              that action, before the remediation action can be reported.
            </div>
            <br />
            <h5>6. Why do some taps have no flush sample?</h5>
            <div>
              Combination fixtures, which have multiple taps (e.g. sink with a
              faucet and a drinking fountain, or a bottle fill station and
              drinking fountain), only require one flush sample. Therefore, only
              one tap associated with that fixture will have a flush sample
              result.
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Faq;
