import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import { Button, Form, Input, Alert, Label, Table } from "reactstrap";
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class ANROnlineUsers extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.fetchNFormUserList = this.fetchNFormUserList.bind(this);
    this.fetchSchoolList = this.fetchSchoolList.bind(this);
    this.addNFormUser = this.addNFormUser.bind(this);
    this.deleteNFormUser = this.deleteNFormUser.bind(this);

    this.state = {
      isAuthenticated: false,
      nformusers: [],
      schools: [],
      School_ID: "",
      nFormUserID: "",
      loading: true,
      error: "",
      message: "",
      showMessageAlert: false,
      showErrorAlert: false
    };
  }

  componentDidMount() {
    this.fetchNFormUserList();
    this.fetchSchoolList();
  }

  fetchNFormUserList() {
    let url;
    url = `${base_URL}/api/internal/nformusers`;

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (json.length === 0) {
          this.setState({
            message: "No nForm Users Found",
            showMessageAlert: true
          });
        }
        this.setState({ nformusers: json, loading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true, loading: false });
      });
  }

  addNFormUser() {
    let url;
    url = `${base_URL}/api/internal/addnformuser`;

    console.log(url);

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        School_ID: this.state.School_ID,
        nFormUserID: this.state.nFormUserID,
        apiKey: apiKey
      })
    })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        this.setState({
          message: json,
          showMessageAlert: true
        });
        this.fetchNFormUserList();
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true });
      });
  }


  deleteNFormUser(School_ID, nFormUserID, e) {
    e.preventDefault();
    let url;
    url = `${base_URL}/api/internal/deletenformuser`;

    console.log(url);

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        School_ID: School_ID,
        nFormUserID: nFormUserID,
        apiKey: apiKey
      })
    })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        this.setState({
          message: json,
          showMessageAlert: true
        });
        this.fetchNFormUserList();
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true });
      });
  }

  fetchSchoolList() {
    let url, useCredentials;

    url = `${base_URL}/api/internal/schools`;
    useCredentials = 'include';

    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        search: "",
        town: "All Towns",
        type: "Both",
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (json.length === 0) {
          this.setState({
            message: "No Schools Found",
            showMessageAlert: true
          });
        }
        this.setState({ schools: json });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true });
      });
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col>
            <br />
            <h4>ANROnline Users</h4>
            <br />
            <span className="login-spinner">
              <BounceLoader
                size={60}
                sizeUnit={"px"}
                color={"#36D7B7"}
                css={override}
                loading={this.state.loading}
              />
            </span>
            {this.state.error !== "" && (
              <Col md={{ size: 6 }}>
                <Alert
                  color="warning"
                  isOpen={this.state.showErrorAlert}
                  toggle={this.toggleErrorAlert}
                >
                  {this.state.error}
                </Alert>
              </Col>
            )}
            {this.state.message !== "" && (
              <Col md={{ size: 6 }}>
                <Alert
                  color="info"
                  isOpen={this.state.showMessageAlert}
                  toggle={this.toggleMessageAlert}
                >
                  {this.state.message}
                </Alert>
              </Col>
            )}
          </Col>
        </Row>
        {this.state.nformusers && this.state.nformusers.length > 0 && (
          <span>
            <Row>
              <Col md={{ size: 6 }} style={{ marginBottom: "1rem" }}>
                {" "}
                <Label for="schoolInput">Provider</Label>
                <Input
                  type="select"
                  name="School_ID"
                  id="schoolInput"
                  value={this.state.School_ID}
                  onChange={this.handleInputChange}
                >
                  <option value={""}>Select a Provider</option>
                  {this.state.schools.length > 0 &&
                    this.state.schools.map(
                      school =>
                        school.School_ID && (
                          <option
                            key={school.School_ID}
                            value={school.School_ID}
                          >
                            {school.School_Name + ", " + school.Town}
                          </option>
                        )
                    )}
                </Input>
              </Col>
              <Col md={{ size: 6 }} style={{ marginBottom: "1rem" }}>
                <Label for="nFormUserInput">Account Email</Label>
                <Input
                  type="text"
                  name="nFormUserID"
                  id="nFormUserInput"
                  onChange={this.handleInputChange}
                  placeholder="Account Email"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={this.addNFormUser} color="primary">
                  Add
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            {/* <i
                  className="fas fa-plus-square"
                  style={{
                    cursor: "pointer",
                    color: "#007bff",
                    fontSize: "1.25rem"
                  }}
                  onClick={() => console.log}
                /> */}
            {this.state.nformusers.length > 0 && (
              <Row>
                <Col>
                  <Table className="users-table">
                    <thead>
                      <tr>
                        <th>Provider ID</th>
                        <th>Provider</th>
                        <th>Submitter</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.nformusers.map(
                        user =>
                          user.School_ID && (
                            <tr key={user.School_ID + " " + user.nFormUserID}>
                              {/* <th  onClick={() => console.log()}>
                            <i className="fas fa-search-location" style={{cursor:'pointer'}}></i>
                            </th> */}
                              <td>
                                <span
                                // className="school-name"
                                // onClick={
                                //   () => console.log()
                                //   // this.handleSelect(user.Email)
                                // }
                                >
                                  {user.School_ID}
                                </span>
                              </td>
                              <td>{user.School_Name}</td>
                              <td>{user.nFormUserID}</td>
                              <td>
                                <i
                                  className="far fa-trash-alt"
                                  style={{ color: "#030", cursor: "pointer" }}
                                  onClick={e =>
                                    this.deleteNFormUser(
                                      user.School_ID,
                                      user.nFormUserID,
                                      e
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </span>
        )}
      </Container>
    );
  }
}

export default ANROnlineUsers;
