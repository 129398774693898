import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, Input, Alert, Label, Table } from "reactstrap";
import { EditStaff } from "./EditStaff.js";
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";

class Register extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.toggleStaffModal = this.toggleStaffModal.bind(this);
    this.fetchUserList = this.fetchUserList.bind(this);

    this.state = {
      isAuthenticated: false,
      users: [],
      staff: {},
      staffModal: false,
      error: "",
      message: "",
      username: "",
      password: "",
      showMessageAlert: false,
      showErrorAlert: false
    };
  }

  componentDidMount() {
    this.fetchUserList();
  }

  toggleStaffModal() {
    this.setState({
      staffModal: !this.state.staffModal,
      staff: {}
    });
    this.fetchUserList();
  }


  handleSelect(Staff) {
    this.setState({
      staffModal: !this.state.staffModal,
      staff: Staff
    })
  }


  fetchUserList() {
    let url;

    url = `${base_URL}/api/internal/users`;

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (json.length === 0) {
          this.setState({
            message: "No Users Found",
            showMessageAlert: true
          });
        }
        console.log(json);
        this.setState({ users: json });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true });
      });
  }


  handleLogin(event) {
    event.preventDefault();
    if (!this.state.username.includes("vermont.gov")) {
      this.setState({ error: 'Username must be a vermont.gov email address', showErrorAlert: true });
      return;
    }
    let url = `${base_URL}/api/Account/Register`;
    // console.log(url);

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify({
        Email: this.state.username,
        Password: this.state.password,
        ConfirmPassword: this.state.password
      })
    })
      .then(response => {
        if (!response.ok) {
          console.log(response);
          let errorMessage;
          if(response.statusText==="Bad Request") {
            errorMessage = response.text()
            .then(text=>{
              console.log('bad body');
              let errorjson =JSON.parse(text);              
              return errorjson.ModelState[Object.keys(errorjson.ModelState)[0]][0];
            });
          } else {
            errorMessage=response.statusText;
          }
          return errorMessage;
        }
        this.setState({
          message: "Successfully Registered",
          showMessageAlert: true,
          error:'',showErrorAlert:false
        });
        this.fetchUserList();
      }).then(caught => {
        if(caught) {
          throw Error(caught);
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true });
      });
  }


  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <>
        <Container style={{ textAlign: "center" }}>
          <Row>
            <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
              <h4>New Account Register</h4>
              <div>Put in the new staff person's vermont.gov email address, make up a password, hit create user, send the user their username and password, and set their role.
                Role options include Internal - Access to view all pages and upload results to test. Upload - Same as Internal plus permission to upload results to production. Administrator - Same as Upload plus permission to add and modify staff accounts here.</div>
              <br />
              <Form onSubmit={this.handleLogin}>
                <Col md={{ size: 6, offset: 3 }} style={{ marginBottom: "1rem" }}>
                  <Label for="userInput">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="userInput"
                    value={this.state.username}
                    onChange={this.handleInputChange}
                    placeholder="Username"
                  />
                </Col>
                <Col md={{ size: 6, offset: 3 }} style={{ marginBottom: "1rem" }}>
                  {" "}
                  <Label for="passInput">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="passInput"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    placeholder="Password"
                  />
                </Col>
                <Col md={{ size: 12 }}>
                  <Button type="submit" color="primary">
                    Create User
                  </Button>
                </Col>
              </Form>
              <br />
              {this.state.error !== "" && (
                <Alert
                  color="warning"
                  isOpen={this.state.showErrorAlert}
                  toggle={this.toggleErrorAlert}
                >
                  {this.state.error}
                </Alert>
              )}
              {this.state.message !== "" && (
                <Alert
                  color="info"
                  isOpen={this.state.showMessageAlert}
                  toggle={this.toggleMessageAlert}
                >
                  {this.state.message}
                </Alert>
              )}
              {this.state.users.length > 0 && (
                <Col xl={{ size: 10, offset: 1 }}>
                  <span>
                    <br />
                    <h5>Staff Accounts</h5>
                    <Table className="users-table">
                      <thead>
                        <tr>
                          <th>Account</th>
                          <th>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.users.map(
                          user =>
                            user.Id && (
                              <tr key={user.Id}>
                                {/* <th  onClick={() => console.log()}>
                              <i className="fas fa-search-location" style={{cursor:'pointer'}}></i>
                              </th> */}
                                <td>
                                  <span
                                    className="school-name"
                                    onClick={
                                      () => this.handleSelect(user)
                                    }
                                  >
                                    {user.Email}
                                  </span>
                                </td>
                                <td>{user.Name}</td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </Table>
                  </span>
                </Col>
              )}
              <br />
            </Col>
          </Row>
        </Container>
        <EditStaff {...this.props} open={this.state.staffModal} cancel={this.toggleStaffModal} staff={this.state.staff} />
      </>
    );
  }
}

export default Register;
