import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Tutorial extends Component {

  render() {
    return (
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col>
            <h4>Tutorial Video</h4>
            <iframe
              title="Lead Results Website Tutorial Video"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/q2d2Oe91Uwo?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            />
            {/* <video width={this.state.width > 960 ? 960: this.state.width-100} height={this.state.width > 960 ? 540 : (this.state.width-100)*0.5625} controls>
              <source src={`${base_URL}/Content/lead-results-website-demo.mp4`} type="video/mp4"></source>
              Your browser does not support the video tag.
            </video> */}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Tutorial;
