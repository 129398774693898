import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";
import { LoadingSpinner } from './LoadingSpinner.js';

export function EditStaff(props) {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [role, setRole] = useState(3);
    const [error, setError] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);


    useEffect(() => {
    }, []);

    useEffect(() => {
        if (props.staff.Email) {
            console.log('staff changed: ', props.staff.Email);
            loadStaff();
        }
    }, [props.staff.Email]);


    const toggleErrorAlert = () => {
        setShowErrorAlert(!showErrorAlert);
        setError("");
    }

    const handleCancel = (event) => {
        event.preventDefault();
        setError("");
        setShowErrorAlert(false);
        setUsername("");
        setRole(3);
        props.cancel();
    }


    const handleDelete = (event) => {
        event.preventDefault();
        deleteStaff();
    }
    const handleSave = (event) => {
        event.preventDefault();
        saveStaff();
    }

    const loadStaff = () => {
        setUsername(props.staff.Email);
        setRole(props.staff.RoleId || 3);
    }


    const saveStaff = () => {
        let url, useCredentials;

        url = `${base_URL}/api/internal/changestaff`;
        useCredentials = 'include';

        setLoading(true);

        console.log(url);
        fetch(url, {
            method: "POST",
            credentials: useCredentials,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ID: props.staff.Id,
                Role: role,
                apiKey: apiKey
            })
        }).then(response => {
            console.log(response);
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.text();
        }).then(text => {
            console.log('text ', text);
            if (text === "\"Success\"") {
                setLoading(false);
                props.cancel()
            } else {
                throw Error(text);
            }
        }).catch(error => {
            console.error(error);
            if (!error.message) {
                error.message = "Error saving staff";
            }
            setError(error.message);
            setShowErrorAlert(true);
            setLoading(false);
        });
    }


    const deleteStaff = () => {
        let url, useCredentials;

        url = `${base_URL}/api/internal/deletestaff`;
        useCredentials = 'include';

        setLoading(true);

        console.log(url);
        fetch(url, {
            method: "POST",
            credentials: useCredentials,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ID: props.staff.Id,
                apiKey: apiKey
            })
        }).then(response => {
            console.log(response);
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.text();
        }).then(text => {
            console.log('text ', text);
            if (text === "\"Success\"") {
                setLoading(false);
                props.cancel()
            } else {
                throw Error(text);
            }
        }).catch(error => {
            console.error(error);
            if (!error.message) {
                error.message = "Error deleting staff";
            }
            setError(error.message);
            setShowErrorAlert(true);
            setLoading(false);
        });
    }



    return (
        <Modal isOpen={props.open} toggle={props.cancel}>
            <Form onSubmit={handleSave}>
                <ModalHeader>Edit Staff Role</ModalHeader>
                <ModalBody>
                    {loading && (
                        <Container>
                            <Row>
                                <Col xs={{ size: 1, offset: 5 }}>
                                    <LoadingSpinner loading={loading} delay={750} />
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {props.staff.Id && (
                        <>
                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="emailInput">Username</Label>
                                        <Input
                                            type="text"
                                            name="email"
                                            id="emailInput"
                                            value={username}
                                            maxLength={255}
                                            onChange={e => setUsername(e.target.value)}
                                            placeholder="Username"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="roleInput">Role</Label>
                                        <Input type="select"
                                            name="type" id="roleInput"
                                            value={role}
                                            onChange={e => setRole(e.target.value)}>
                                            <option key={3} value={3}>
                                                {'Internal'}</option>
                                            <option key={2} value={2}>
                                                {'Upload'}</option>
                                            <option key={1} value={1}>
                                                {'Administrator'}</option>
                                        </Input>

                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    )}
                    {error !== "" && (
                        <Row>
                            <Col>
                                <Alert
                                    color="warning"
                                    isOpen={showErrorAlert}
                                    toggle={toggleErrorAlert}
                                >
                                    {error}
                                </Alert>
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="primary" onClick={handleSave}>
                        Save
                    </Button>{` `}
                    <Button color="primary" onClick={handleDelete}>
                        Delete
                    </Button>{` `}
                    <Button color="primary" onClick={handleCancel}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

