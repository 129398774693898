import React, { Component } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import { CSVLink } from "react-csv";
import {
  Alert,
} from "reactstrap";
import { base_URL, credentials } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
}

class Summary extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      data: {},
      report: "",
      error: "",
      message: "",
      showMessageAlert: false,
      showErrorAlert: false,
      isTest: false,
      isInternal: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  handleSelect(School_ID) {
    this.props.history.push({ pathname: "/school/" + School_ID });
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  fetchData(event) {
    let url, useCredentials;

    url = `${base_URL}/api/public/kpi`;
    
    useCredentials = credentials;

    // console.log(url);
    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (!json || json.length === 0) {
          let message;
          message = "No Data Found";
          this.setState({
            message: message,
            showMessageAlert: true,
            data: {},
            loading: false
          });

          return;
        }
        this.setState({ data: json, loading: false });
      })
      .catch(error => {
        this.setState({
          error: error.message,
          showErrorAlert: true,
          data: {},
          loading: false
        });
      });
  }

  render() {
    return (
      <span>
        {this.state.data && (
          <Container style={{ textAlign: "center" }}>
            <Row>
              <Col>
                {<span className="h4">Overall Summary as of {new Date().toLocaleDateString('en-US', options)}</span>}
                {/* {" "} */}
                {/* {this.state.data.length > 0 && (
                  <CSVLink
                    data={this.state.data}
                    filename={"Report Data.csv"}
                    target="_blank"
                  >
                    <i
                      className="fas fa-file-csv"
                      alt="Download as CSV"
                      style={{
                        cursor: "pointer",
                        fontSize: "1.75rem",
                        color: "#1d6f42"
                      }}
                    ></i>{" "}
                  </CSVLink>
                )} */}
                <br />
                <br />
                <span className="login-spinner">
                  <BounceLoader
                    size={60}
                    sizeUnit={"px"}
                    color={"#36D7B7"}
                    css={override}
                    loading={this.state.loading}
                  />
                </span>
                {this.state.error !== "" && (
                  <Alert
                    color="warning"
                    isOpen={this.state.showErrorAlert}
                    toggle={this.toggleErrorAlert}
                  >
                    {this.state.error}
                  </Alert>
                )}
                {this.state.message !== "" && (
                  <Alert
                    color="info"
                    isOpen={this.state.showMessageAlert}
                    toggle={this.toggleMessageAlert}
                  >
                    {this.state.message}
                  </Alert>
                )}
                {this.state.data.length > 0 && (
                  <Col xl={{size:"10", offset:"1"}}>
                    <span>
                      <Table bordered className="kpi-table">
                        <thead>
                          <tr>
                            <th style={{textAlign: 'left'}}>Category</th>
                            <th style={{textAlign: 'right'}}>Number</th>
                            <th style={{textAlign: 'left'}}>Percent of Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map(
                            datum =>
                              datum.Indicator && (
                                <tr key={datum.Indicator}>
                                  <td style={{textAlign: 'left'}}>{datum.Indicator}</td>
                                  <td style={{textAlign: 'right'}}>{Number(datum.Count).toLocaleString('EN-us')}</td>
                                  <td style={{textAlign: 'left'}}>{datum.Rate ? parseFloat(datum.Rate*100).toFixed(0)+"% "+datum.Denominator : "N/A"}</td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </Table>
                    </span>
                  </Col>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </span>
    );
  }
}

export default Summary;
