import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


export class LoadingSpinner extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
    };
  }


  componentDidMount() {
    this.timer = setTimeout(
      () => this.setState({ showSpinner: true }),
      this.props.delay
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return (
      this.state.showSpinner && <Loader
        type="Oval"
        color="#00BFFF"
        height={this.props.height ? this.props.height : 60}
        width={this.props.width ? this.props.width : 60}
        visible={this.props.loading}
      />
    );
  }
}
