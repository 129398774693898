import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import MaterialTable from "material-table";
import { Button, Form, Input, Alert, Label, Table } from "reactstrap";
import { EditSchoolModal } from "./EditSchoolModal.js";
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class SchoolEditor extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleSchoolModal = this.toggleSchoolModal.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.fetchSchoolList = this.fetchSchoolList.bind(this);

    this.state = {
      isAuthenticated: false,
      schools: [],
      school: {},
      schoolModal: false,
      loading: true,
      env: "",
      error: "",
      message: "",
      showMessageAlert: false,
      showErrorAlert: false
    };
  }

  componentDidMount() {
    const env = this.props.match.params.env;
    this.setState({ env: env });
    this.fetchSchoolList(env);
  }


  componentDidUpdate(prevProps) {
    const env = this.props.match.params.env;
    console.log(this.state.env, env);
    if (this.state.env !== env) {
      this.setState({ env: env });
      this.fetchSchoolList(env);
    }
  }

  toggleSchoolModal() {
    this.setState({
      schoolModal: !this.state.schoolModal,
      school: {}
    });
    const env = this.props.match.params.env;
    this.fetchSchoolList(env);
  }


  handleSelect(School) {
    this.setState({
      schoolModal: !this.state.schoolModal,
      school: School
    })
  }


  fetchSchoolList() {
    let url, useCredentials;

    let env = this.props.match.params.env;
    let suffix;

    if (env === 'prod') {
      suffix = 'schooleditor';
    }
    if (env === 'test') {
      suffix = 'schooleditortest';
    }

    url = `${base_URL}/api/internal/${suffix}`;
    useCredentials = 'include';

    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        if (json.length === 0) {
          this.setState({
            message: "No Schools Found",
            showMessageAlert: true
          });
        }
        this.setState({ schools: json, loading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true, loading: false });
      });
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <>
        <Container style={{ textAlign: "center" }}>

          <Row>
            <Col>
              <br />
                <h4>{this.state.env === 'test' ? 'TEST ' : ''}School and Child Care Name Management</h4>

              <br />
              <span className="login-spinner">
                <BounceLoader
                  size={60}
                  sizeUnit={"px"}
                  color={"#36D7B7"}
                  css={override}
                  loading={this.state.loading}
                />
              </span>
              {this.state.error !== "" && (
                <Col md={{ size: 6 }}>
                  <Alert
                    color="warning"
                    isOpen={this.state.showErrorAlert}
                    toggle={this.toggleErrorAlert}
                  >
                    {this.state.error}
                  </Alert>
                </Col>
              )}
              {this.state.message !== "" && (
                <Col md={{ size: 6 }}>
                  <Alert
                    color="info"
                    isOpen={this.state.showMessageAlert}
                    toggle={this.toggleMessageAlert}
                  >
                    {this.state.message}
                  </Alert>
                </Col>
              )}
            </Col>
          </Row>
          {this.state.schools && this.state.schools.length > 0 && (
            <div className="search-table" style={{ maxWidth: "100%" }}>
              <MaterialTable
                className="custom-material-table"
                minRows={1}
                columns={[
                  {
                    title: "Facility",
                    field: "Display_Name",
                    render: rowData => <span
                      className="table-select"
                      onClick={() =>
                        this.handleSelect(rowData)
                      }
                    >
                      {rowData.Active_Facility === true ? rowData.Display_Name : rowData.Display_Name.concat(" (Inactive)")}
                    </span>
                  },
                  { title: "Town", field: "Town" },
                  { title: "Type", field: "Type" },
                  { title: "AOE ID", field: "AOE_ID" }
                ]}
                data={this.state.schools}
                title="Schools and Child Cares"
                options={
                  this.state.schools.length < 10 ?
                    {
                      paging: false,
                      search: false
                    } :
                    {
                      pageSize: 5,
                      pageSizeOptions: this.state.schools.length < 25 ? [10, this.state.schools.length] : this.state.schools.length < 25 ? [5, 10, this.state.schools.length] : [5, 10, 25, this.state.schools.length]
                    }}
              />
            </div>
          )}
        </Container>
        <EditSchoolModal {...this.props} open={this.state.schoolModal} cancel={this.toggleSchoolModal} school={this.state.school} env={this.state.env} />
      </>

    );
  }
}

export default SchoolEditor;
