import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-table/react-table.css'
import "./index.css";
import "core-js"
import "regenerator-runtime/runtime";
import * as serviceWorker from "./serviceWorker";
import App from "./components/App.js";



ReactDOM.render(
  <App />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


// This project builds for anrweb with npm run build
// Builds for testweb with npm run build-testweb
// And localhost with npm run start

// To ensure refreshes route back to the homepage in production builds, 
// set the publish location in package.json before building
// "homepage": "http://dotnetv4.vtanr.state.vt.us/leadinschools",
// "homepage": "https://anrweb.vt.gov/DEC/leadinschools",