import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Button, Form, Input, Alert, Label } from "reactstrap";
import { base_URL } from "../config/buildSettings.js";
import { CircleLoader } from "react-spinners";

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      username: "",
      password: "",
      error: "",
      message: "",
      loading: false,
      showMessageAlert: false,
      showErrorAlert: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleLogin(event) {
    event.preventDefault();

    let url = `${base_URL}/Token`;

    this.setState({ loading: true });

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body:
        "grant_type=password&username=" +
        this.state.username +
        "&password=" +
        this.state.password
    })
      .then(response => {
        this.setState({ loading: false });
        if (!response.ok) {
          throw Error("Invalid Login");
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        // console.log(json);
        this.props.onLogin();
      })
      .catch(error => {
        this.setState({
          error: error.message,
          showErrorAlert: true,
          loading: false
        });
      });
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  render() {
    return (
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <h4>State of Vermont Staff Login</h4>
            <br />
            <Form onSubmit={this.handleLogin}>
              <Col md={{ size: 6, offset: 3 }} style={{ marginBottom: "1rem" }}>
                <Label for="userInput">Username</Label>
                <Input
                  type="text"
                  name="username"
                  id="userInput"
                  value={this.state.username}
                  onChange={this.handleInputChange}
                  placeholder="Username"
                />
              </Col>
              <Col md={{ size: 6, offset: 3 }} style={{ marginBottom: "1rem" }}>
                {" "}
                <Label for="passInput">Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="passInput"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  placeholder="Password"
                />
              </Col>
              <Col md={{ size: 12 }}>
                <Button type="submit" color="primary">
                  {this.state.loading ? (
                    <span className="login-spinner">
                      <CircleLoader size={22} sizeUnit={"px"} color={"#fff"} loading={this.state.loading} />
                    </span>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Col>
            </Form>
            <br />
            {this.state.error !== "" && (
              <Alert
                color="warning"
                isOpen={this.showErrorAlert}
                toggle={this.toggleErrorAlert}
              >
                {this.state.error}
              </Alert>
            )}
            {this.state.message !== "" && (
              <Alert
                color="info"
                isOpen={this.showMessageAlert}
                toggle={this.toggleMessageAlert}
              >
                {this.state.message}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
