import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardDeck,
  Alert,
  ListGroup,
  ListGroupItem,
  Input
} from "reactstrap";
import { CSVLink } from "react-csv";
import ReactExport from "react-export-excel";
import { base_URL, credentials } from "../config/buildSettings.js";
import { apiKey, actionLevel } from "../config/index.js";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class School extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: {},
      resultsSummary: {},
      resultsRaw: {},
      resultsSummaryRaw: {},
      resultsRawDownload: {},
      remediation: {},
      school: "",
      school_data: {},
      years: [],
      message: "",
      error: "",
      Sort: "Tap",
      SortCard: "Highest Tap Result, desc",
      Lab_ID_Sort: "",
      Collection_Date_Sort: "",
      Analysis_Date_Sort: "",
      Collection_Type_Sort: "",
      View: "Tap Summary Table",
      Broken_Only: false,
      Tap_Sort: "asc",
      Result_Sort: "",
      Summary_Sort: "Highest Tap Result",
      Summary_Tap_Sort: "",
      Summary_First_Sort: "",
      Summary_Flush_Sort: "",
      Summary_Date_Sampled_Sort: "",
      Summary_Date_Analyzed_Sort: "",
      Summary_Action_Taken_Sort: "",
      Summary_Date_Action_Taken_Sort: "",
      Summary_Highest_Tap_Result_Sort: "desc",
      summaryLoading: true,
      resultsLoading: true,
      schoolLoading: true,
      resultsRawDownloadLoading: true,
      stats: false
    };

    this.renderSort = this.renderSort.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onSortSummary = this.onSortSummary.bind(this);
    this.onSortSummaryCards = this.onSortSummaryCards.bind(this);
    this.getDownloadData = this.getDownloadData.bind(this);
    this.getSchool = this.getSchool.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.getResults = this.getResults.bind(this);
    this.getResultsRawDownload = this.getResultsRawDownload.bind(this);
    this.getDownloadDataResults = this.getDownloadDataResults.bind(this);
    this.filterData = this.filterData.bind(this);
    this.filterSummary = this.filterSummary.bind(this);
    this.filterResults = this.filterResults.bind(this);
  }

  async componentDidMount() {
    const school = this.props.match.params.school;
    this.setState({ school: school });
    if (window.innerWidth < 600) {
      this.setState({ View: "Tap Summary Cards" });
    }
    this.getSchool();
    this.getResults();
    this.getSummary();
    this.getResultsRawDownload();
  }

  getSchool() {

    const school = this.props.match.params.school;

    let url, useCredentials;

    // Use credentials if test or internal
    if (this.props.location.pathname.search("test") !== -1) {
      url = `${base_URL}/api/internal/schooltest`;
      useCredentials = "include";
    } else if (this.props.location.pathname.search("internal") !== -1) {
      url = `${base_URL}/api/internal/school`;
      useCredentials = "include";
    } else {
      url = `${base_URL}/api/public/school`;
      useCredentials = credentials;
    }

    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        school: school,
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (json.length === 0) {
          this.setState({
            message: "No School Found",
            showMessageAlert: true,
            schoolLoading: false
          });
        }

        console.log('school ', json);

        this.setState({ school_data: json, schoolLoading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error: error.message,
          showErrorAlert: true,
          schoolLoading: false
        });
      });
  }

  getResults() {

    let url, useCredentials;

    // Use credentials if test or internal
    if (this.props.location.pathname.search("test") !== -1) {
      url = `${base_URL}/api/internal/individualtest`;
      useCredentials = "include";
    } else if (this.props.location.pathname.search("internal") !== -1) {
      url = `${base_URL}/api/internal/individual`;
      useCredentials = "include";
    } else {
      url = `${base_URL}/api/public/individual`;
      useCredentials = credentials;
    }

    const school = this.props.match.params.school;

    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        school: school,
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        console.log('results ', json);
        if (json.length === 0) {
          this.setState({
            message: "No Results Found",
            showMessageAlert: true,
            resultsLoading: false
          });
        } else {
          this.setState({
            resultsRaw: json, resultsLoading: false
          }, () => {

            const resultsYearSummary = json.reduce((res, obj) => {
              if (!(obj.Initial_Sampling_Year in res)) {
                res.__array.push(res[obj.Initial_Sampling_Year] = { year: obj.Initial_Sampling_Year, count: 0 });
              }
              res[obj.Initial_Sampling_Year].count++;
              return res;
            }, { __array: [] }).__array.sort((a, b) => { return b.year - a.year });

            if (resultsYearSummary.length > 1) {
              this.filterData(resultsYearSummary[0].year);
            } else {
              this.filterData(0);
            }
          });
        };
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error: error.message,
          showErrorAlert: true,
          resultsLoading: false
        });
      });
  }

  getSummary() {

    let url, useCredentials;

    // Use credentials if test
    if (this.props.location.pathname.search("test") !== -1) {
      url = `${base_URL}/api/internal/summarytest`;
      useCredentials = "include";
    } else if (this.props.location.pathname.search("internal") !== -1) {
      url = `${base_URL}/api/internal/summary`;
      useCredentials = "include";
    } else {
      url = `${base_URL}/api/public/summary`;
      useCredentials = credentials;
    }

    const school = this.props.match.params.school;

    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        school: school,
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        console.log('summary ', json);
        if (json.length === 0) {
          this.setState({
            summaryLoading: false,
            View: "All Results"
          }, () => {
            this.filterData(0);
          });
        } else {
          const yearSummary = json.reduce((res, obj) => {
            if (!(obj.Initial_Sampling_Year in res)) {
              res.__array.push(res[obj.Initial_Sampling_Year] = { year: obj.Initial_Sampling_Year, count: 0 });
            }
            res[obj.Initial_Sampling_Year].count++;
            return res;
          }, { __array: [] }).__array.sort((a, b) => { return b.year - a.year });

          this.setState({ yearSummary: yearSummary, resultsSummaryRaw: json, summaryLoading: false, round: yearSummary[0].year }, () => {
            if (yearSummary.length > 1) {
              this.filterData(this.state.round);
            } else {
              this.filterData(0);
            }
          });

          let all_rounds_need_remediation = json.filter(summary => {
            if (summary.Exceeded === 1 && (summary.Remediated === 0 || (summary.No_Follow_Up_Remediation === 0 && summary.Needs_Follow === 1))) {
              return true;
            } else {
              return false;
            }
          }).length;

          this.setState({ all_rounds_need_remediation: all_rounds_need_remediation });

        }

      })
      .catch(error => {
        console.error(error);
        this.setState({
          error: error.message,
          showErrorAlert: true,
          summaryLoading: false
        });
      });

  }


  getResultsRawDownload() {

    let url, useCredentials;

    // Use credentials if test or internal
    if (this.props.location.pathname.search("test") !== -1) {
      url = `${base_URL}/api/internal/rawdownloadtest`;
      useCredentials = "include";
    } else if (this.props.location.pathname.search("internal") !== -1) {
      url = `${base_URL}/api/internal/rawdownload`;
      useCredentials = "include";
    } else {
      return;
    }

    const school = this.props.match.params.school;

    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        school: school,
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        console.log('results raw ', json);
        if (json.length === 0) {
          this.setState({
            message: "Failed to download raw results",
            showMessageAlert: true,
            resultsRawDownloadLoading: false
          });
        } else {
          this.setState({
            resultsRawDownload: json, resultsRawDownloadLoading: false
          });
        };
      })
      .catch(error => {
        console.error(error);
        this.setState({
          error: error.message,
          showErrorAlert: true,
          resultsRawDownloadLoading: false
        });
      });
  }

  filterSummary(year) {
    if (this.state.resultsSummaryRaw && this.state.resultsSummaryRaw.length > 0) {
      let summaryData = this.state.resultsSummaryRaw.filter(summary => {
        if (year === 0) {
          return true;
        } else {
          if (summary.Initial_Sampling_Year === year) {
            return true;
          } else {
            return false;
          }
        }
      });


      let taps_tested = summaryData.length;

      let taps_exceeding = summaryData.filter(summary => {
        if (summary.Exceeded === 1) {
          return true;
        } else {
          return false;
        }
      }).length;


      let taps_remediated = summaryData.filter(summary => {
        if (summary.Exceeded === 1 && ((summary.Remediated === 1 && ((summary.No_Follow_Up_Remediation === 1 && summary.Needs_DEC_Resampling === 0) || summary.Needs_Follow === 0)) || (summary.Permanent_Remediation_Details && summary.No_Follow_Up_Remediation === 1 && summary.Permanent_Remediation_Details !== 'DEC Sanctioned Resampling'))) {
          return true;
        } else {
          return false;
        }
      }).length;

      let taps_remaining = summaryData.filter(summary => {
        if (summary.Exceeded === 1 && (summary.Remediated === 0 || (summary.No_Follow_Up_Remediation === 0 && summary.Needs_Follow === 1)) && !(summary.Permanent_Remediation_Details && summary.No_Follow_Up_Remediation === 1 && summary.Permanent_Remediation_Details !== 'DEC Sanctioned Resampling')) {
          return true;
        } else {
          return false;
        }
      }).length;


      let taps_DEC_resampling = summaryData.filter(summary => {
        if (summary.Exceeded === 1 && summary.Needs_DEC_Resampling === 1) {
          return true;
        } else {
          return false;
        }
      }).length;

      this.setState({ taps_tested: taps_tested, taps_exceeding: taps_exceeding, taps_remediated: taps_remediated, taps_remaining: taps_remaining, taps_DEC_resampling: taps_DEC_resampling });

      this.setState({ resultsSummary: summaryData, round: year });
    }
  }


  filterResults(year) {
    if (this.state.resultsRaw && this.state.resultsRaw.length > 0) {
      let resultsData = this.state.resultsRaw.filter(result => {
        if (year === 0) {
          return true;
        } else {
          if (result.Initial_Sampling_Year === year) {
            return true;
          } else {
            return false;
          }
        }
      });

      this.setState({ results: resultsData, round: year });
    }
  }

  filterData(year) {
    this.filterSummary(year);
    this.filterResults(year);
  }

  renderSort(status, field, current) {
    if (field !== current) {
      return <i className="fas fa-sort" />;
    }

    switch (status) {
      case "":
        return <i className="fas fa-sort" />;
      case "asc":
        return <i className="fas fa-sort-up" />;
      case "desc":
        return <i className="fas fa-sort-down" />;
      default:
        return <span />;
    }
  }

  onSort(sortKey) {
    const results = this.state.results;
    let sort;
    switch (sortKey) {
      case "Lab_ID":
        sort = this.state.Lab_ID_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          results.sort((b, a) => a["Lab_ID"].localeCompare(b["Lab_ID"]));
        } else {
          sort = "asc";
          results.sort((a, b) => a["Lab_ID"].localeCompare(b["Lab_ID"]));
        }
        this.setState({ Lab_ID_Sort: sort, Sort: "Lab_ID" });
        break;
      case "Collection_Date":
        sort = this.state.Collection_Date_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          results.sort(
            (b, a) => new Date(a["Sample_Date"]) - new Date(b["Sample_Date"])
          );
        } else {
          sort = "asc";
          results.sort(
            (a, b) => new Date(a["Sample_Date"]) - new Date(b["Sample_Date"])
          );
        }
        this.setState({ Collection_Date_Sort: sort, Sort: "Collection_Date" });
        break;
      case "Analysis_Date":
        sort = this.state.Analysis_Date_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          results.sort(
            (b, a) =>
              new Date(a["Analysis_Date"]) - new Date(b["Analysis_Date"])
          );
        } else {
          sort = "asc";
          results.sort(
            (a, b) =>
              new Date(a["Analysis_Date"]) - new Date(b["Analysis_Date"])
          );
        }
        this.setState({ Analysis_Date_Sort: sort, Sort: "Analysis_Date" });
        break;
      case "Collection_Type":
        sort = this.state.Collection_Type_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          results.sort((b, a) =>
            a["Sample_Collection_Type"].localeCompare(
              b["Sample_Collection_Type"]
            )
          );
        } else {
          sort = "asc";
          results.sort((a, b) =>
            a["Sample_Collection_Type"].localeCompare(
              b["Sample_Collection_Type"]
            )
          );
        }
        this.setState({ Collection_Type_Sort: sort, Sort: "Collection_Type" });
        break;
      case "Tap":
        sort = this.state.Tap_Sort;
        if (sort === "" || sort === "desc") {
          sort = "asc";
          results.sort((a, b) => a["Tap"].localeCompare(b["Tap"]));
        } else {
          sort = "desc";
          results.sort((b, a) => a["Tap"].localeCompare(b["Tap"]));
        }
        this.setState({ Tap_Sort: sort, Sort: "Tap" });
        break;
      case "Result":
        sort = this.state.Result_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          results.sort((b, a) =>
            a["Raw_Result"] ? a["Raw_Result"] - b["Raw_Result"] : -1
          );
        } else {
          sort = "asc";
          results.sort((a, b) =>
            a["Raw_Result"] ? a["Raw_Result"] - b["Raw_Result"] : -1
          );
        }
        this.setState({ Result_Sort: sort, Sort: "Result" });
        break;
      default:
        break;
    }
    this.setState({ results });
  }

  onSortSummary(sortKey) {
    const resultsSummary = this.state.resultsSummary;
    let sort;
    switch (sortKey) {
      case "Tap":
        sort = this.state.Summary_Tap_Sort;
        if (sort === "" || sort === "desc") {
          sort = "asc";
          resultsSummary.sort((a, b) => a["Tap"].localeCompare(b["Tap"]));
        } else {
          sort = "desc";
          resultsSummary.sort((b, a) => a["Tap"].localeCompare(b["Tap"]));
        }
        this.setState({ Summary_Tap_Sort: sort, Summary_Sort: "Tap" });
        break;
      case "First Draw":
        sort = this.state.Summary_First_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          resultsSummary.sort((b, a) =>
            a["Raw_First_Draw"] ? a["Raw_First_Draw"] - b["Raw_First_Draw"] : -1
          );
        } else {
          sort = "asc";
          resultsSummary.sort((a, b) =>
            a["Raw_First_Draw"] ? a["Raw_First_Draw"] - b["Raw_First_Draw"] : -1
          );
        }
        this.setState({ Summary_First_Sort: sort, Summary_Sort: "First Draw" });
        break;
      case "Flush Sample":
        sort = this.state.Summary_Flush_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          resultsSummary.sort((b, a) => {
            if (a["Flush"]) {
              if (b["Flush"]) {
                return a["Raw_Flush"] ? a["Raw_Flush"] - b["Raw_Flush"] : -1;
              } else {
                return 1;
              }
            } else {
              return -1;
            }
          });
        } else {
          sort = "asc";
          resultsSummary.sort((a, b) => {
            if (a["Flush"]) {
              if (b["Flush"]) {
                return a["Raw_Flush"] ? a["Raw_Flush"] - b["Raw_Flush"] : -1;
              } else {
                return -1;
              }
            } else {
              return 1;
            }
          });
        }
        this.setState({
          Summary_Flush_Sort: sort,
          Summary_Sort: "Flush Sample"
        });
        break;
      case "Date Sampled":
        sort = this.state.Summary_Date_Sampled_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          resultsSummary.sort(
            (b, a) =>
              new Date(a["Earliest_Sample_Date"]) -
              new Date(b["Earliest_Sample_Date"])
          );
        } else {
          sort = "asc";
          resultsSummary.sort(
            (a, b) =>
              new Date(a["Earliest_Sample_Date"]) -
              new Date(b["Earliest_Sample_Date"])
          );
        }
        this.setState({
          Summary_Date_Sampled_Sort: sort,
          Summary_Sort: "Date Sampled"
        });
        break;
      case "Date Analyzed":
        sort = this.state.Summary_Date_Analyzed_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          resultsSummary.sort(
            (b, a) =>
              new Date(a["Earliest_Analysis_Date"]) -
              new Date(b["Earliest_Analysis_Date"])
          );
        } else {
          sort = "asc";
          resultsSummary.sort(
            (a, b) =>
              new Date(a["Earliest_Analysis_Date"]) -
              new Date(b["Earliest_Analysis_Date"])
          );
        }
        this.setState({
          Summary_Date_Analyzed_Sort: sort,
          Summary_Sort: "Date Analyzed"
        });
        break;
      case "Action Taken":
        sort = this.state.Summary_Action_Taken_Sort;
        if (sort === "" || sort === "desc") {
          sort = "asc";
          resultsSummary.sort((a, b) => {
            if (a["Remediation_Details"]) {
              if (b["Remediation_Details"]) {
                return a["Remediation_Details"].localeCompare(
                  b["Remediation_Details"]
                );
              } else {
                return -1;
              }
            } else {
              return 1;
            }
          });
        } else {
          sort = "desc";
          resultsSummary.sort((b, a) => {
            if (a["Remediation_Details"]) {
              if (b["Remediation_Details"]) {
                return a["Remediation_Details"].localeCompare(
                  b["Remediation_Details"]
                );
              } else {
                return 1;
              }
            } else {
              return -1;
            }
          });
        }
        this.setState({
          Summary_Action_Taken_Sort: sort,
          Summary_Sort: "Action Taken"
        });
        break;
      case "Date Action Taken":
        sort = this.state.Summary_Date_Action_Taken_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          resultsSummary.sort((b, a) => {
            if (a["Remediation_Date"]) {
              if (b["Remediation_Date"]) {
                return (
                  new Date(a["Remediation_Date"]) -
                  new Date(b["Remediation_Date"])
                );
              } else {
                return 1;
              }
            } else {
              return -1;
            }
          });
        } else {
          sort = "asc";
          resultsSummary.sort((a, b) => {
            if (a["Remediation_Date"]) {
              if (b["Remediation_Date"]) {
                return (
                  new Date(a["Remediation_Date"]) -
                  new Date(b["Remediation_Date"])
                );
              } else {
                return -1;
              }
            } else {
              return 1;
            }
          });
        }
        this.setState({
          Summary_Date_Action_Taken_Sort: sort,
          Summary_Sort: "Date Action Taken"
        });
        break;
      case "Highest Tap Result":
        sort = this.state.Highest_Tap_Result_Sort;
        if (sort === "" || sort === "asc") {
          sort = "desc";
          resultsSummary.sort((b, a) =>
            a["Highest_Result"] ? a["Highest_Result"] - b["Highest_Result"] : -1
          );
        } else {
          sort = "asc";
          resultsSummary.sort((a, b) =>
            a["Highest_Result"] ? a["Highest_Result"] - b["Highest_Result"] : -1
          );
        }
        this.setState({
          Highest_Tap_Result_Sort: sort,
          Summary_Sort: "Highest Tap Result"
        });
        break;
      default:
        break;
    }
    this.setState({ resultsSummary });
  }

  onSortSummaryCards(event) {
    const resultsSummary = this.state.resultsSummary;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    const sortCard = value;
    //console.log(value);
    switch (sortCard) {
      case "Tap, asc":
        resultsSummary.sort((a, b) => a["Tap"].localeCompare(b["Tap"]));
        this.setState({ Summary_Tap_Sort: "asc", Summary_Sort: "Tap" });
        break;
      case "Tap, desc":
        resultsSummary.sort((b, a) => a["Tap"].localeCompare(b["Tap"]));
        this.setState({ Summary_Tap_Sort: "desc", Summary_Sort: "Tap" });
        break;
      case "First Draw, desc":
        resultsSummary.sort((b, a) =>
          a["Raw_First_Draw"] ? a["Raw_First_Draw"] - b["Raw_First_Draw"] : -1
        );
        this.setState({
          Summary_First_Sort: "desc",
          Summary_Sort: "First Draw"
        });
        break;
      case "First Draw, asc":
        resultsSummary.sort((a, b) =>
          a["Raw_First_Draw"] ? a["Raw_First_Draw"] - b["Raw_First_Draw"] : -1
        );
        this.setState({
          Summary_First_Sort: "asc",
          Summary_Sort: "First Draw"
        });
        break;
      case "Flush Sample, desc":
        resultsSummary.sort((b, a) => {
          if (a["Flush"]) {
            if (b["Flush"]) {
              return a["Raw_Flush"] ? a["Raw_Flush"] - b["Raw_Flush"] : -1;
            } else {
              return 1;
            }
          } else {
            return -1;
          }
        });
        this.setState({
          Summary_Flush_Sort: "desc",
          Summary_Sort: "Flush Sample"
        });
        break;
      case "Flush Sample, asc":
        resultsSummary.sort((a, b) => {
          if (a["Flush"]) {
            if (b["Flush"]) {
              return a["Raw_Flush"] ? a["Raw_Flush"] - b["Raw_Flush"] : -1;
            } else {
              return -1;
            }
          } else {
            return 1;
          }
        });
        this.setState({
          Summary_Flush_Sort: "asc",
          Summary_Sort: "Flush Sample"
        });
        break;
      case "Date Sampled, desc":
        resultsSummary.sort(
          (b, a) =>
            new Date(a["Earliest_Sample_Date"]) -
            new Date(b["Earliest_Sample_Date"])
        );
        this.setState({
          Summary_Date_Sampled_Sort: "desc",
          Summary_Sort: "Date Sampled"
        });
        break;
      case "Date Sampled, asc":
        resultsSummary.sort(
          (a, b) =>
            new Date(a["Earliest_Sample_Date"]) -
            new Date(b["Earliest_Sample_Date"])
        );
        this.setState({
          Summary_Date_Sampled_Sort: "asc",
          Summary_Sort: "Date Sampled"
        });
        break;
      case "Date Analyzed, desc":
        resultsSummary.sort(
          (b, a) =>
            new Date(a["Earliest_Analysis_Date"]) -
            new Date(b["Earliest_Analysis_Date"])
        );
        this.setState({
          Summary_Date_Analyzed_Sort: "desc",
          Summary_Sort: "Date Analyzed"
        });
        break;
      case "Date Analyzed, asc":
        resultsSummary.sort(
          (a, b) =>
            new Date(a["Earliest_Analysis_Date"]) -
            new Date(b["Earliest_Analysis_Date"])
        );
        this.setState({
          Summary_Date_Analyzed_Sort: "asc",
          Summary_Sort: "Date Analyzed"
        });
        break;
      case "Action Taken, asc":
        resultsSummary.sort((a, b) => {
          if (a["Remediation_Details"]) {
            if (b["Remediation_Details"]) {
              return a["Remediation_Details"].localeCompare(
                b["Remediation_Details"]
              );
            } else {
              return -1;
            }
          } else {
            return 1;
          }
        });
        this.setState({
          Summary_Action_Taken_Sort: "asc",
          Summary_Sort: "Action Taken"
        });
        break;
      case "Action Taken, desc":
        resultsSummary.sort((b, a) => {
          if (a["Remediation_Details"]) {
            if (b["Remediation_Details"]) {
              return a["Remediation_Details"].localeCompare(
                b["Remediation_Details"]
              );
            } else {
              return 1;
            }
          } else {
            return -1;
          }
        });
        this.setState({
          Summary_Action_Taken_Sort: "desc",
          Summary_Sort: "Action Taken"
        });
        break;
      case "Date Action Taken, desc":
        resultsSummary.sort((b, a) => {
          if (a["Remediation_Date"]) {
            if (b["Remediation_Date"]) {
              return (
                new Date(a["Remediation_Date"]) -
                new Date(b["Remediation_Date"])
              );
            } else {
              return 1;
            }
          } else {
            return -1;
          }
        });
        this.setState({
          Summary_Date_Action_Taken_Sort: "desc",
          Summary_Sort: "Date Action Taken"
        });
        break;

      case "Date Action Taken, asc":
        resultsSummary.sort((a, b) => {
          if (a["Remediation_Date"]) {
            if (b["Remediation_Date"]) {
              return (
                new Date(a["Remediation_Date"]) -
                new Date(b["Remediation_Date"])
              );
            } else {
              return -1;
            }
          } else {
            return 1;
          }
        });
        this.setState({
          Summary_Date_Action_Taken_Sort: "asc",
          Summary_Sort: "Date Action Taken"
        });
        break;
      case "Highest Tap Result, desc":
        resultsSummary.sort((b, a) =>
          a["Highest_Result"] ? a["Highest_Result"] - b["Highest_Result"] : -1
        );
        this.setState({
          Summary_First_Sort: "desc",
          Summary_Sort: "Highest Tap Result"
        });
        break;
      case "Highest Tap Result, asc":
        resultsSummary.sort((a, b) =>
          a["Highest_Result"] ? a["Highest_Result"] - b["Highest_Result"] : -1
        );
        this.setState({
          Summary_First_Sort: "asc",
          Summary_Sort: "Highest Tap Result"
        });
        break;
      default:
        break;
    }
    this.setState({ resultsSummary });
  }

  getDownloadData() {
    if (!this.state.summaryLoading) {
      const dataSet = [
        {
          columns: [
            "School or Childcare Name",
            "Town",
            "First Draw Initial Lab ID",
            "Tap",
            "First Draw",
            "Flush",
            "Date Sampled",
            "Date Analyzed",
            "Permanent Action Taken",
            "Permanent Action Date",
            "Immediate Action Taken",
            "Immediate Action Date",
            "Planned Action"
          ],
          data: []
        }
      ];

      this.state.resultsSummary.filter(summary => {
        if (!this.state.Broken_Only) {
          return true;
        } else {
          if (summary.Need_Fix === 1) {
            return true;
          } else {
            return false;
          }
        }
      }).map(tap => {
        var obj = [
          { value: tap.School_Name === null ? "" : tap.School_Name },
          { value: tap.Town_For_Sample === null ? "" : tap.Town_For_Sample },
          {
            value:
              tap.Initial_First_Lab_ID === null ? "" : tap.Initial_First_Lab_ID
          },
          { value: tap.Tap === null ? "" : tap.Tap },
          {
            value: tap.First_Draw === null ? "" : tap.First_Draw,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_First_Draw >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          {
            value: tap.Flush === null ? "" : tap.Flush,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Flush >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          { value: tap.Sample_Date === null ? "" : tap.Sample_Date },
          { value: tap.Analysis_Date === null ? "" : tap.Analysis_Date },
          {
            value:
              tap.Permanent_Remediation_Details === null
                ? ""
                : tap.Permanent_Remediation_Details
          },
          {
            value:
              tap.Permanent_Remediation_Date === null
                ? ""
                : tap.Permanent_Remediation_Date
          },
          {
            value:
              tap.Immediate_Remediation_Details === null
                ? ""
                : tap.Immediate_Remediation_Details
          },
          {
            value:
              tap.Immediate_Remediation_Date === null
                ? ""
                : tap.Immediate_Remediation_Date
          },
          {
            value:
              tap.Planned_Remediation_Details === null
                ? ""
                : tap.Planned_Remediation_Details
          }
        ];
        dataSet[0].data.push(obj);
      });

      // console.log(dataSet);
      return dataSet;
    }
  }

  getDownloadDataResults() {
    if (!this.state.resultsLoading) {
      const dataSet = [
        {
          columns: [
            "School or Childcare Name",
            "Town",
            "Lab ID",
            "Date Sampled",
            "Date Analyzed",
            "Collection Type",
            "Tap",
            "Result",
            "Initial_Lab_ID",
            "First_Draw_Lab_ID"
          ],
          data: []
        }
      ];

      this.state.results.map(tap => {
        var obj = [
          {
            value: tap.School_Name === null ? "" : tap.School_Name,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Result >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          {
            value: tap.Town_For_Sample === null ? "" : tap.Town_For_Sample,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Result >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          {
            value: tap.Lab_ID === null ? "" : tap.Lab_ID,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Result >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          {
            value: tap.Sample_Date === null ? "" : tap.Sample_Date,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Result >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          {
            value: tap.Analysis_Date === null ? "" : tap.Analysis_Date,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Result >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          {
            value:
              tap.Sample_Collection_Type === null
                ? ""
                : tap.Sample_Collection_Type,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Result >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          {
            value: tap.Tap === null ? "" : tap.Tap,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Result >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          },
          {
            value: tap.Result === null ? "" : tap.Result,
            style: {
              font: {
                color: {
                  rgb: tap.Raw_Result >= actionLevel ? "C64A37" : "212528"
                }
              }
            }
          }
        ];
        dataSet[0].data.push(obj);
      });

      // console.log(dataSet);
      return dataSet;
    }
  }

  render() {
    // const columns = [
    //   {
    //     Header: "Lab ID",
    //     accessor: "Lab_ID"
    //   },
    //   {
    //     Header: "Collection Date",
    //     accessor: "Sample_Date"
    //   },
    //   {
    //     id: "collectionType",
    //     Header: "Collection Type",
    //     accessor: d => d.Sample_Type + " " + d.Collection_Type
    //   },
    //   {
    //     Header: "Tap",
    //     accessor: "Tap",
    //     style: { 'white-space' : 'unset' }
    //   },
    //   {
    //     Header: "Result",
    //     accessor: "Result",
    //     getProps: (state, rowInfo, column) => {
    //       return {
    //         style: {
    //           color:
    //             rowInfo && rowInfo.original.Raw_Result >= actionLevel
    //               ? "#c64a37"
    //               : "#212528"
    //         }
    //       };
    //     }
    //   }
    // ];

    return (
      <span>
        <Container style={{ textAlign: "center" }}>
          <Row>
            <Col>
              {this.state.school_data.length > 0 &&
                this.state.school_data[0].School_Name && (
                  <>
                    <h4>
                      {this.state.school_data[0].Active_Facility === true ? this.state.school_data[0].School_Name : this.state.school_data[0].School_Name.concat(" (Inactive)")}
                      <span className="town-heading">
                        , {this.state.school_data[0].Town}
                        {this.props.location.pathname.search("test") !== -1
                          ? ", Test Site"
                          : ""}
                        {this.props.location.pathname.search("internal") !== -1
                          ? ", Internal Site"
                          : ""}
                      </span>
                    </h4>
                    <span style={{ textAlign: "left", display: "block" }}>
                      Results at or above{" "}
                      <span style={{ fontWeight: 700, color: "#c64a37" }}>
                        4 ppb
                      </span>{" "}
                      are shown in{" "}
                      <span style={{ fontWeight: 700, color: "#c64a37" }}>
                        bold dark red with an asterisk *
                      </span>
                      . 4 ppb is Vermont’s action level for schools and child
                      cares under Act 66 (2019), the law requiring lead testing
                      in water at all Vermont schools and child care facilities.
                      Any tap with a level at or above 4 ppb is required to be
                      immediately removed from service for drinking and cooking,
                      and then be permanently remediated, or fixed, until levels
                      are below 4 ppb.
                    </span>
                    <br />
                  </>
                )}
              {this.state.results.length > 0 &&
                this.state.results[0].School_Name &&
                !this.state.schoolLoading &&
                this.state.school_data.length > 0 &&
                this.state.school_data[0].School_Name &&
                this.state.yearSummary &&
                this.state.yearSummary.length > 0 && (
                  <>
                    <ButtonGroup>
                      <Button
                        outline
                        color="info"
                        onClick={() => {
                          if (this.state.yearSummary.length > 1) {
                            this.filterData(this.state.yearSummary[0].year)
                          } else {
                            this.filterData(0)
                          }
                        }}
                        active={this.state.yearSummary.length === 1 || this.state.round === this.state.yearSummary[0].year}
                      >
                        {`Latest Sampling (${this.state.yearSummary[0].year})`}
                      </Button>
                      {this.state.yearSummary.length > 1 && (
                        <>
                          {this.state.yearSummary.slice(1).map(
                            year =>
                              year.year && (
                                <Button
                                  key={year.year}
                                  outline
                                  color="info"
                                  onClick={() => this.filterData(year.year)}
                                  active={this.state.round === year.year}
                                >
                                  {`${year.year} Sampling`}
                                </Button>
                              )
                          )}
                          <Button
                            outline
                            color="info"
                            onClick={() => this.filterData(0)}
                            active={this.state.round === 0}
                          >
                            {`All Sampling`}
                          </Button>
                        </>)}
                    </ButtonGroup>
                    <br />
                    <br />
                    {this.state.taps_tested && (
                      <Col
                        sm={{ size: 12 }}
                        md={{ size: 12 }}
                        lg={{ size: 6, offset: 3 }}>
                        <Card style={{ marginBottom: "2rem" }}>
                          <CardHeader
                            style={{ backgroundColor: "#e4f2e3" }}
                          >
                            {`Summary Statistics (${this.state.round === 0 ? 'All' : this.state.round} Sampling)`}
                          </CardHeader>
                          {!this.state.stats && (
                            <>
                              <div style={{ marginTop: "0.5rem" }}>Taps that need to be fixed: <strong>{this.state.taps_remaining}</strong></div>
                              {this.state.taps_DEC_resampling > 0 && (
                                <div>Taps that need to be tested again due to error: <strong>{this.state.taps_DEC_resampling}</strong></div>
                              )}
                              <div onClick={() => this.setState({ stats: true })} className="school-name" style={{ marginBottom: "1rem" }}>More</div>
                            </>
                          )}
                          {this.state.stats && (
                            <>
                              <div style={{ marginTop: "0.5rem" }}>Taps tested: <strong>{this.state.taps_tested}</strong></div>
                              <div>Taps at or above the action limit: <strong>{this.state.taps_exceeding}</strong></div>
                              <div>Taps that have been fixed: <strong>{this.state.taps_remediated}</strong></div>
                              <div>Taps that need to be fixed: <strong>{this.state.taps_remaining}</strong></div>
                              {this.state.taps_DEC_resampling > 0 && (
                                <div>Taps that need to be tested again due to error: <strong>{this.state.taps_DEC_resampling}</strong></div>
                              )}
                              <div onClick={() => this.setState({ stats: false })} className="school-name" style={{ marginBottom: "1rem" }}>Less</div>
                            </>
                          )}
                          {/* {this.state.stats && this.state.round !== 0 && (
                            <>
                              <div style={{ marginTop: "0.5rem" }}>Taps tested: <strong>{this.state.taps_tested}</strong></div>
                              <div>Taps at or above the action limit from this sampling round: <strong>{this.state.taps_exceeding}</strong></div>
                              <div>Taps that have been fixed from this sampling round: <strong>{this.state.taps_remediated}</strong></div>
                              <div>Taps that need to be fixed from this sampling round: <strong>{this.state.taps_remaining}</strong></div>
                              <div onClick={() => this.setState({ stats: false })} className="school-name" style={{ marginBottom: "1rem" }}>Less</div>
                            </>
                          )} */}
                        </Card></Col>
                    )}
                    <ButtonGroup>
                      <Button
                        outline
                        color="info"
                        onClick={() =>
                          this.setState({ View: "Tap Summary Table" })
                        }
                        active={this.state.View === "Tap Summary Table"}
                      >
                        Tap Summary Table
                      </Button>
                      <Button
                        outline
                        color="info"
                        onClick={() =>
                          this.setState({ View: "Tap Summary Cards" })
                        }
                        active={this.state.View === "Tap Summary Cards"}
                      >
                        Tap Summary Cards
                      </Button>
                      <Button
                        outline
                        color="info"
                        onClick={() => this.setState({ View: "All Results", Broken_Only: false })}
                        active={this.state.View === "All Results"}
                      >
                        All Results
                      </Button>
                    </ButtonGroup>
                    <br />
                    <br />
                    {Boolean((this.state.taps_remaining && this.state.taps_remaining > 0) && (this.state.View === "Tap Summary Table" || this.state.View === "Tap Summary Cards")) && (
                      <>
                        <ButtonGroup>
                          <Button
                            outline
                            color="info"
                            onClick={() => this.setState({ Broken_Only: false })}
                            active={this.state.Broken_Only === false}
                          >
                            All Taps
                          </Button>
                          <Button
                            outline
                            color="info"
                            onClick={() => this.setState({ Broken_Only: true })}
                            active={this.state.Broken_Only === true}
                          >
                            Only Taps Needing to be Fixed
                          </Button>
                        </ButtonGroup>
                        <br />
                        <br />
                      </>
                    )}
                  </>
                )}
              {!this.state.resultsRawDownloadLoading && (
                <>
                  <CSVLink
                    data={this.state.resultsRawDownload}
                    filename={
                      `${this.state.resultsRawDownload[0].School_Name === null ? this.state.resultsRawDownload[0].Provider_Name : this.state.resultsRawDownload[0].School_Name} ${this.state.results[0] ? this.state.results[0].Town_For_Sample : this.state.resultsRawDownload[0].Town_For_Sample} Raw Results.csv`
                    }
                    target="_blank"
                  >
                    <Button outline color="info">Download Raw Uploaded Results</Button>
                    {/* <i className="fas fa-file-csv"
                          alt="Download as CSV"
                          style={{ cursor: "pointer", fontSize: '1.75rem', color: '#1d6f42' }}></i>{' '} */}
                  </CSVLink>
                  <br />
                  <br />
                </>
              )}
              {this.state.error !== "" && (
                <Alert
                  color="warning"
                  isOpen={this.state.showErrorAlert}
                  toggle={this.toggleErrorAlert}
                >
                  {this.state.error}
                </Alert>
              )}
              {this.state.message !== "" && (
                <Alert
                  color="info"
                  isOpen={this.state.showMessageAlert}
                  toggle={this.toggleMessageAlert}
                >
                  {this.state.message}
                </Alert>
              )}

              {/* {this.state.results.length > 0 && (
                <span>
                  <h5>Sample Results</h5>
                  <br />
                  <ReactTable
                    data={this.state.results}
                    columns={columns}
                    showPagination={false}
                    minRows={1}
                    className="-striped -highlight"
                  />
                </span>
              )} */}
              <span className="login-spinner">
                <BounceLoader
                  size={60}
                  sizeUnit={"px"}
                  color={"#36D7B7"}
                  css={override}
                  loading={
                    this.state.summaryLoading ||
                    this.state.resultsLoading ||
                    this.state.schoolLoading
                  }
                />
              </span>
              {!this.state.summaryLoading &&
                !this.state.resultsLoading &&
                !this.state.schoolLoading && (
                  <span>
                    {this.state.resultsSummary.length > 0 &&
                      this.state.results.length > 0 &&
                      !this.state.schoolLoading &&
                      this.state.school_data.length > 0 &&
                      this.state.school_data[0].School_Name &&
                      this.state.View === "Tap Summary Cards" && (
                        <span className="tap-summaries">
                          {/* Tap Summaries */}
                          <span className="h5">
                            Summary of Lead in Drinking Water Results and
                            Remediation Actions for{" "}
                            {this.state.school_data[0].Active_Facility === true ? this.state.school_data[0].School_Name : this.state.school_data[0].School_Name.concat(" (Inactive)")},{" "}
                            {this.state.school_data[0].Town}
                          </span>{" "}
                          <CSVLink
                            data={this.state.resultsSummary.filter(summary => {
                              if (!this.state.Broken_Only) {
                                return true;
                              } else {
                                if (summary.Need_Fix === 1) {
                                  return true;
                                } else {
                                  return false;
                                }
                              }
                            }).map(result => {
                              let rObj = {};
                              rObj["School or Childcare Name"] =
                                result.School_Name;
                              rObj["Town"] = result.Town_For_Sample;
                              rObj["First Draw Initial Lab ID"] =
                                result.Initial_First_Lab_ID;
                              rObj["Tap"] = result.Tap;
                              rObj["First Draw"] = result.First_Draw;
                              rObj["Flush"] = result.Flush;
                              rObj["Date Sampled"] = result.Sample_Date;
                              rObj["Date Analyzed"] = result.Analysis_Date;
                              rObj["Permanent Action Taken"] =
                                result.Permanent_Remediation_Details;
                              rObj["Permanent Action Date"] =
                                result.Permanent_Remediation_Date;
                              rObj["Immediate Action Taken"] =
                                result.Immediate_Remediation_Details;
                              rObj["Immediate Action Date"] =
                                result.Immediate_Remediation_Date;
                              rObj["Planned Action"] =
                                result.Planned_Remediation_Details;
                              return rObj;
                            })}
                            filename={`${this.state.resultsSummary[0].School_Name} ${this.state.resultsSummary[0].Town_For_Sample} ${this.state.round === 0 ? "All" : this.state.round + " Sampling"} Lead Results Summary.csv`}
                            target="_blank"
                          >
                            <i
                              className="fas fa-file-csv"
                              alt="Download as CSV"
                              style={{
                                cursor: "pointer",
                                fontSize: "1.75rem",
                                color: "#1d6f42"
                              }}
                            ></i>{" "}
                          </CSVLink>
                          <ExcelFile
                            element={
                              <i
                                className="fas fa-file-excel"
                                alt="Download as Excel"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1.75rem",
                                  color: "#1d6f42"
                                }}
                              ></i>
                            }
                            filename={`${this.state.resultsSummary[0].School_Name} ${this.state.resultsSummary[0].Town_For_Sample} ${this.state.round === 0 ? "All" : this.state.round + " Sampling"} Lead Results Summary`}
                          >
                            <ExcelSheet
                              dataSet={this.getDownloadData()}
                              name="Data"
                            />
                          </ExcelFile>
                          <br />
                          <br />
                          <Row>
                            <Col
                              xs={{ size: 10, offset: 1 }}
                              md={{ size: 6, offset: 3 }}
                              lg={{ size: 4, offset: 4 }}
                            >
                              <Input
                                size="sm"
                                type="select"
                                name="SortCard"
                                id="sortInput"
                                value={this.state.SortCard}
                                onChange={this.onSortSummaryCards}
                              >
                                <option key="Tap, asc" value="Tap, asc">
                                  {this.state.SortCard === "Tap, asc"
                                    ? "Sort by: Tap (A-Z)"
                                    : "Tap (A-Z)"}
                                </option>
                                <option key="Tap, desc" value="Tap, desc">
                                  {this.state.SortCard === "Tap, desc"
                                    ? "Sort by: Tap (Z-A)"
                                    : "Tap (Z-A)"}
                                </option>
                                <option
                                  key="Highest Tap Result, desc"
                                  value="Highest Tap Result, desc"
                                >
                                  {this.state.SortCard ===
                                    "Highest Tap Result, desc"
                                    ? "Sort by: Highest Tap Result (Highest to Lowest)"
                                    : "Highest Tap Result (Highest to Lowest)"}
                                </option>
                                <option
                                  key="Highest Tap Result, asc"
                                  value="Highest Tap Result, asc"
                                >
                                  {this.state.SortCard ===
                                    "Highest Tap Result, asc"
                                    ? "Sort by: Highest Tap Result (Lowest to Highest)"
                                    : "Highest Tap Result (Lowest to Highest)"}
                                </option>
                                <option
                                  key="First Draw, desc"
                                  value="First Draw, desc"
                                >
                                  {this.state.SortCard === "First Draw, desc"
                                    ? "Sort by: First Draw (Highest to Lowest)"
                                    : "First Draw (Highest to Lowest)"}
                                </option>
                                <option
                                  key="First Draw, asc"
                                  value="First Draw, asc"
                                >
                                  {this.state.SortCard === "First Draw, asc"
                                    ? "Sort by: First Draw (Lowest to Highest)"
                                    : "First Draw (Lowest to Highest)"}
                                </option>
                                <option
                                  key="Flush Sample, desc"
                                  value="Flush Sample, desc"
                                >
                                  {this.state.SortCard === "Flush Sample, desc"
                                    ? "Sort by: Flush (Highest to Lowest)"
                                    : "Flush (Highest to Lowest)"}
                                </option>
                                <option
                                  key="Flush Sample, asc"
                                  value="Flush Sample, asc"
                                >
                                  {this.state.SortCard === "Flush Sample, asc"
                                    ? "Sort by: Flush (Lowest to Highest)"
                                    : "Flush (Lowest to Highest)"}
                                </option>
                                <option
                                  key="Date Sampled, desc"
                                  value="Date Sampled, desc"
                                >
                                  {this.state.SortCard === "Date Sampled, desc"
                                    ? "Sort by: Date Sampled (Newest to Oldest)"
                                    : "Date Sampled (Newest to Oldest)"}
                                </option>
                                <option
                                  key="Date Sampled, asc"
                                  value="Date Sampled, asc"
                                >
                                  {this.state.SortCard === "Date Sampled, asc"
                                    ? "Sort by: Date Sampled (Oldest to Newest)"
                                    : "Date Sampled (Oldest to Newest)"}
                                </option>
                                <option
                                  key="Date Analyzed, desc"
                                  value="Date Analyzed, desc"
                                >
                                  {this.state.SortCard === "Date Analyzed, desc"
                                    ? "Sort by: Date Analyzed (Newest to Oldest)"
                                    : "Date Analyzed (Newest to Oldest)"}
                                </option>
                                <option
                                  key="Date Analyzed, asc"
                                  value="Date Analyzed, asc"
                                >
                                  {this.state.SortCard === "Date Analyzed, asc"
                                    ? "Sort by: Date Analyzed (Oldest to Newest)"
                                    : "Date Analyzed (Oldest to Newest)"}
                                </option>
                                <option
                                  key="Action Taken, asc"
                                  value="Action Taken, asc"
                                >
                                  {this.state.SortCard === "Action Taken, asc"
                                    ? "Sort by: Action Taken (A-Z)"
                                    : "Action Taken (A-Z)"}
                                </option>
                                <option
                                  key="Action Taken, desc"
                                  value="Action Taken, desc"
                                >
                                  {this.state.SortCard === "Action Taken, desc"
                                    ? "Sort by: Action Taken (Z-A)"
                                    : "Action Taken (Z-A)"}
                                </option>
                                <option
                                  key="Date Action Taken, desc"
                                  value="Date Action Taken, desc"
                                >
                                  {this.state.SortCard ===
                                    "Date Action Taken, desc"
                                    ? "Sort by: Date Action Taken (Newest to Oldest)"
                                    : "Date Action Taken (Newest to Oldest)"}
                                </option>
                                <option
                                  key="Date Action Taken, asc"
                                  value="Date Action Taken, asc"
                                >
                                  {this.state.SortCard ===
                                    "Date Action Taken, asc"
                                    ? "Sort by: Date Action Taken (Oldest to Newest)"
                                    : "Date Action Taken (Oldest to Newest)"}
                                </option>
                              </Input>
                            </Col>
                          </Row>
                          <br />
                          <CardDeck>
                            {this.state.resultsSummary.filter(summary => {
                              if (!this.state.Broken_Only) {
                                return true;
                              } else {
                                if (summary.Need_Fix === 1) {
                                  return true;
                                } else {
                                  return false;
                                }
                              }
                            }).map(
                              summary =>
                                summary.Initial_First_Lab_ID && (
                                  <Col
                                    sm="12"
                                    md="6"
                                    lg="4"
                                    key={summary.Initial_First_Lab_ID}
                                  >
                                    <Card style={{ marginBottom: "2rem" }}>
                                      <CardHeader
                                        style={{ backgroundColor: "#e4f2e3" }}
                                      >
                                        {summary.Tap}
                                        <br />
                                        Initial First Draw Lab ID#:{" "}
                                        {summary.Initial_First_Lab_ID}
                                      </CardHeader>
                                      <ListGroup flush>
                                        {!summary.Initial_First_Draw &&
                                          !summary.Initial_Flush &&
                                          // !summary.Initial_Ten &&
                                          !summary.Follow_First_Draw &&
                                          !summary.Follow_Flush &&
                                          // !summary.Follow_Ten &&
                                          !summary.Remediation_Details &&
                                          !summary.Remediation_Date && (
                                            <ListGroupItem>
                                              <span
                                                style={{
                                                  fontWeight: 500,
                                                  color: "#c64a37"
                                                }}
                                              >
                                                Samples Reported, No Valid
                                                Sample/Collection Type
                                              </span>
                                            </ListGroupItem>
                                          )}
                                        {summary.Initial_First_Draw && (
                                          <ListGroupItem>
                                            Initial First Draw:{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  summary.Initial_Raw_First_Draw &&
                                                    summary.Initial_Raw_First_Draw >=
                                                    actionLevel
                                                    ? 700
                                                    : 500,
                                                color:
                                                  summary.Initial_Raw_First_Draw &&
                                                    summary.Initial_Raw_First_Draw >=
                                                    actionLevel
                                                    ? "#c64a37"
                                                    : "#212528"
                                              }}
                                            >
                                              {summary.Initial_Raw_First_Draw &&
                                                summary.Initial_Raw_First_Draw >=
                                                actionLevel
                                                ? summary.Initial_First_Draw +
                                                "*"
                                                : summary.Initial_First_Draw}
                                            </span>
                                            <br />
                                            <span>Collected: </span>
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {
                                                summary.Initial_First_Sample_Date
                                              }
                                            </span>
                                            <br />
                                            <span>Analyzed: </span>
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {
                                                summary.Initial_First_Analysis_Date
                                              }
                                            </span>
                                          </ListGroupItem>
                                        )}
                                        {summary.Initial_Flush && (
                                          <ListGroupItem>
                                            Initial Flush:{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  summary.Initial_Raw_Flush &&
                                                    summary.Initial_Raw_Flush >=
                                                    actionLevel
                                                    ? 700
                                                    : 500,
                                                color:
                                                  summary.Initial_Raw_Flush &&
                                                    summary.Initial_Raw_Flush >=
                                                    actionLevel
                                                    ? "#c64a37"
                                                    : "#212528"
                                              }}
                                            >
                                              {summary.Initial_Raw_Flush &&
                                                summary.Initial_Raw_Flush >=
                                                actionLevel
                                                ? summary.Initial_Flush + "*"
                                                : summary.Initial_Flush}
                                            </span>
                                            <br />
                                            <span>Collected: </span>
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {
                                                summary.Initial_Flush_Sample_Date
                                              }
                                            </span>
                                            <br />
                                            <span>Analyzed: </span>
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {
                                                summary.Initial_Flush_Analysis_Date
                                              }
                                            </span>
                                          </ListGroupItem>
                                        )}
                                        {/* {summary.Initial_Ten && (
                                      <ListGroupItem>
                                        Initial 10-minute Flush:{" "}
                                        <span
                                          style={{
                                            fontWeight: 500,
                                            color:
                                              summary.Initial_Raw_Ten &&
                                              summary.Initial_Raw_Ten >= actionLevel
                                                ? "#c64a37"
                                                : "#212528"
                                          }}
                                        >
                                          {summary.Initial_Ten}
                                        </span><br />
                                        <span
                                          style={{
                                            fontWeight: 500
                                          }}
                                        >
                                          {summary.Initial_Ten_Sample_Date}
                                        </span>
                                      </ListGroupItem>
                                    )} */}
                                        {summary.Follow_First_Draw && (
                                          <ListGroupItem>
                                            Follow-up First Draw:{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  summary.Follow_Raw_First_Draw &&
                                                    summary.Follow_Raw_First_Draw >=
                                                    actionLevel
                                                    ? 700
                                                    : 500,
                                                color:
                                                  summary.Follow_Raw_First_Draw &&
                                                    summary.Follow_Raw_First_Draw >=
                                                    actionLevel
                                                    ? "#c64a37"
                                                    : "#212528"
                                              }}
                                            >
                                              {summary.Follow_Raw_First_Draw &&
                                                summary.Follow_Raw_First_Draw >=
                                                actionLevel
                                                ? summary.Follow_First_Draw +
                                                "*"
                                                : summary.Follow_First_Draw}
                                            </span>
                                            <br />
                                            <span>Collected: </span>
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {summary.Follow_First_Sample_Date}
                                            </span>
                                            <br />
                                            <span>Analyzed: </span>
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {
                                                summary.Follow_First_Analysis_Date
                                              }
                                            </span>
                                          </ListGroupItem>
                                        )}
                                        {summary.Follow_Flush && (
                                          <ListGroupItem>
                                            Follow-up Flush:{" "}
                                            <span
                                              style={{
                                                fontWeight:
                                                  summary.Follow_Raw_Flush &&
                                                    summary.Follow_Raw_Flush >=
                                                    actionLevel
                                                    ? 700
                                                    : 500,
                                                color:
                                                  summary.Follow_Raw_Flush &&
                                                    summary.Follow_Raw_Flush >=
                                                    actionLevel
                                                    ? "#c64a37"
                                                    : "#212528"
                                              }}
                                            >
                                              {summary.Follow_Raw_Flush &&
                                                summary.Follow_Raw_Flush >=
                                                actionLevel
                                                ? summary.Follow_Flush + "*"
                                                : summary.Follow_Flush}
                                            </span>
                                            <br />
                                            <span>Collected: </span>
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {summary.Follow_Flush_Sample_Date}
                                            </span>
                                            <br />
                                            <span>Analyzed: </span>
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {
                                                summary.Follow_Flush_Analysis_Date
                                              }
                                            </span>
                                          </ListGroupItem>
                                        )}
                                        {/* {summary.Follow_Ten && (
                                      <ListGroupItem>
                                        Follow-up 10-minute Flush:{" "}
                                        <span
                                          style={{
                                            fontWeight: 500,
                                            color:
                                              summary.Follow_Raw_Ten &&
                                              summary.Follow_Raw_Ten >= actionLevel
                                                ? "#c64a37"
                                                : "#212528"
                                          }}
                                        >
                                          {summary.Follow_Ten}
                                        </span><br />
                                        <span
                                          style={{
                                            fontWeight: 500
                                          }}
                                        >
                                          {summary.Follow_Ten_Sample_Date}
                                        </span>
                                      </ListGroupItem>
                                    )} */}
                                        {summary.Remediation_Details && (
                                          <ListGroupItem>
                                            Action Taken:{" "}
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {summary.Remediation_Details}
                                            </span>
                                          </ListGroupItem>
                                        )}
                                        {summary.Remediation_Date && (
                                          <ListGroupItem>
                                            Date Action Taken:{" "}
                                            <span
                                              style={{
                                                fontWeight: 500
                                              }}
                                            >
                                              {summary.Remediation_Date}
                                            </span>
                                          </ListGroupItem>
                                        )}
                                      </ListGroup>
                                    </Card>
                                  </Col>
                                )
                            )}
                          </CardDeck>
                          <br />
                        </span>
                      )}
                    {/* End Tap Summaries */}
                    {this.state.resultsSummary.length > 0 &&
                      this.state.results.length > 0 &&
                      !this.state.schoolLoading &&
                      this.state.school_data.length > 0 &&
                      this.state.school_data[0].School_Name &&
                      this.state.View === "Tap Summary Table" && (
                        <span className="results-summary">
                          <span className="h5">
                            Summary of Lead in Drinking Water Results and
                            Remediation Actions for{" "}
                            {this.state.school_data[0].Active_Facility === true ? this.state.school_data[0].School_Name : this.state.school_data[0].School_Name.concat(" (Inactive)")},{" "}
                            {this.state.school_data[0].Town}
                          </span>{" "}
                          <CSVLink
                            data={this.state.resultsSummary.filter(summary => {
                              if (!this.state.Broken_Only) {
                                return true;
                              } else {
                                if (summary.Need_Fix === 1) {
                                  return true;
                                } else {
                                  return false;
                                }
                              }
                            }).map(result => {
                              let rObj = {};
                              rObj["School or Childcare Name"] =
                                result.School_Name;
                              rObj["Town"] = result.Town_For_Sample;
                              rObj["First Draw Initial Lab ID"] =
                                result.Initial_First_Lab_ID;
                              rObj["Tap"] = result.Tap;
                              rObj["First Draw"] = result.First_Draw;
                              rObj["Flush"] = result.Flush;
                              rObj["Date Sampled"] = result.Sample_Date;
                              rObj["Date Analyzed"] = result.Analysis_Date;
                              rObj["Permanent Action Taken"] =
                                result.Permanent_Remediation_Details;
                              rObj["Permanent Action Date"] =
                                result.Permanent_Remediation_Date;
                              rObj["Immediate Action Taken"] =
                                result.Immediate_Remediation_Details;
                              rObj["Immediate Action Date"] =
                                result.Immediate_Remediation_Date;
                              rObj["Planned Action"] =
                                result.Planned_Remediation_Details;
                              return rObj;
                            })}
                            filename={`${this.state.resultsSummary[0].School_Name} ${this.state.resultsSummary[0].Town_For_Sample} ${this.state.round === 0 ? "All" : this.state.round + " Sampling"} Lead Results Summary.csv`}
                            target="_blank"
                          >
                            <i
                              className="fas fa-file-csv"
                              alt="Download as CSV"
                              style={{
                                cursor: "pointer",
                                fontSize: "1.75rem",
                                color: "#1d6f42"
                              }}
                            ></i>{" "}
                          </CSVLink>
                          <ExcelFile
                            element={
                              <i
                                className="fas fa-file-excel"
                                alt="Download as Excel"
                                style={{
                                  cursor: "pointer",
                                  fontSize: "1.75rem",
                                  color: "#1d6f42"
                                }}
                              ></i>
                            }
                            filename={`${this.state.resultsSummary[0].School_Name} ${this.state.resultsSummary[0].Town_For_Sample} ${this.state.round === 0 ? "All" : this.state.round + " Sampling"} Lead Results Summary`}
                          >
                            <ExcelSheet
                              dataSet={this.getDownloadData()}
                              name="Data"
                            />
                          </ExcelFile>
                          <br />
                          <br />
                          <div className="table-wrapper">
                            <Table className="results-table" striped bordered>
                              <thead>
                                <tr>
                                  <th
                                    className="sortable-header"
                                    onClick={() => this.onSortSummary("Tap")}
                                  >
                                    Tap{" "}
                                    {this.renderSort(
                                      this.state.Summary_Tap_Sort,
                                      "Tap",
                                      this.state.Summary_Sort
                                    )}
                                  </th>
                                  {/* <th>Building</th>
                          <th>Location</th>
                          <th>Fixture</th> */}
                                  {/* <th>Initial First Draw</th>
                        <th>Initial Flush</th>
                        <th>Follow-up First Draw</th>
                        <th>Follow-up Flush</th> */}
                                  <th
                                    className="sortable-header"
                                    onClick={() =>
                                      this.onSortSummary("First Draw")
                                    }
                                  >
                                    First Draw{" "}
                                    {this.renderSort(
                                      this.state.Summary_First_Sort,
                                      "First Draw",
                                      this.state.Summary_Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() =>
                                      this.onSortSummary("Flush Sample")
                                    }
                                  >
                                    Flush{" "}
                                    {this.renderSort(
                                      this.state.Summary_Flush_Sort,
                                      "Flush Sample",
                                      this.state.Summary_Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() =>
                                      this.onSortSummary("Date Sampled")
                                    }
                                  >
                                    Date Sampled{" "}
                                    {this.renderSort(
                                      this.state.Summary_Date_Sampled_Sort,
                                      "Date Sampled",
                                      this.state.Summary_Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() =>
                                      this.onSortSummary("Date Analyzed")
                                    }
                                  >
                                    Date Analyzed{" "}
                                    {this.renderSort(
                                      this.state.Summary_Date_Analyzed_Sort,
                                      "Date Analyzed",
                                      this.state.Summary_Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() =>
                                      this.onSortSummary("Action Taken")
                                    }
                                  >
                                    Action Taken{" "}
                                    {this.renderSort(
                                      this.state.Summary_Action_Taken_Sort,
                                      "Action Taken",
                                      this.state.Summary_Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() =>
                                      this.onSortSummary("Date Action Taken")
                                    }
                                  >
                                    Date Action Taken{" "}
                                    {this.renderSort(
                                      this.state.Summary_Date_Action_Taken_Sort,
                                      "Date Action Taken",
                                      this.state.Summary_Sort
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.resultsSummary.filter(summary => {
                                  if (!this.state.Broken_Only) {
                                    return true;
                                  } else {
                                    if (summary.Need_Fix === 1) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  }
                                }).map(
                                  summary =>
                                    summary.Tap && (
                                      <tr
                                        key={summary.Initial_First_Lab_ID}
                                        className="results-row"
                                      >
                                        <td>{summary.Tap}</td>
                                        {/* <td>{summary.Building}</td>
                                  <td>{summary.Fixture_Location}</td>
                                  <td>{summary.Fixture_Type}</td> */}
                                        <td
                                          style={{
                                            color:
                                              summary.Raw_First_Draw &&
                                                summary.Raw_First_Draw >=
                                                actionLevel
                                                ? "#c64a37"
                                                : "#212528",
                                            fontWeight:
                                              summary.Raw_First_Draw &&
                                                summary.Raw_First_Draw >=
                                                actionLevel
                                                ? 700
                                                : 500,
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {summary.Raw_First_Draw &&
                                            summary.Raw_First_Draw >= actionLevel
                                            ? summary.First_Draw + "*"
                                            : summary.First_Draw}
                                        </td>
                                        <td
                                          style={{
                                            color:
                                              summary.Raw_Flush &&
                                                summary.Raw_Flush >= actionLevel
                                                ? "#c64a37"
                                                : "#212528",
                                            fontWeight:
                                              summary.Raw_Flush &&
                                                summary.Raw_Flush >= actionLevel
                                                ? 700
                                                : 500,
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {summary.Raw_Flush &&
                                            summary.Raw_Flush >= actionLevel
                                            ? summary.Flush + "*"
                                            : summary.Flush}
                                        </td>
                                        <td>{summary.Sample_Date}</td>
                                        <td>{summary.Analysis_Date}</td>
                                        {/* <td
                                style={{
                                  color:
                                    summary.Initial_Raw_First_Draw &&
                                    summary.Initial_Raw_First_Draw >= actionLevel
                                      ? "#c64a37"
                                      : "#212528"
                                }}
                              >
                                {summary.Initial_First_Draw}
                              </td>
                              <td
                                style={{
                                  color:
                                    summary.Initial_Raw_Flush &&
                                    summary.Initial_Raw_Flush >= actionLevel
                                      ? "#c64a37"
                                      : "#212528"
                                }}
                              >
                                {summary.Initial_Flush}
                              </td>
                              <td
                                style={{
                                  color:
                                    summary.Follow_Raw_First_Draw &&
                                    summary.Follow_Raw_First_Draw >= actionLevel
                                      ? "#c64a37"
                                      : "#212528"
                                }}
                              >
                                {summary.Follow_First_Draw}
                              </td>
                              <td
                                style={{
                                  color:
                                    summary.Follow_Raw_Flush &&
                                    summary.Follow_Raw_Flush >= actionLevel
                                      ? "#c64a37"
                                      : "#212528"
                                }}
                              >
                                {summary.Follow_Flush}
                              </td> */}
                                        <td>{summary.Remediation_Details}</td>
                                        <td>{summary.Remediation_Date}</td>
                                      </tr>
                                      /* {summary.Remediation_Details && (
                                  <tr className="remediation-row">
                                    <th colspan="6">
                                      Action Taken: {summary.Remediation_Details}
                                    </td>
                                  </tr>
                                )} */
                                    )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </span>
                      )}
                    {this.state.results.length > 0 &&
                      this.state.school_data.length > 0 &&
                      this.state.school_data[0].School_Name &&
                      this.state.View === "All Results" &&
                      !this.state.schoolLoading && (
                        <span>
                          <span>
                            <span className="h5">
                              All Lead in Drinking Water Results for{" "}
                              {this.state.school_data[0].Active_Facility === true ? this.state.school_data[0].School_Name : this.state.school_data[0].School_Name.concat(" (Inactive)")},{" "}
                              {this.state.school_data[0].Town}
                            </span>
                            {` `}
                            <CSVLink
                              data={this.state.results.map(result => {
                                let rObj = {};
                                rObj["School or Childcare Name"] =
                                  result.School_Name;
                                rObj["Town"] = result.Town_For_Sample;
                                rObj["Lab ID"] = result.Lab_ID;
                                rObj["Collection Date"] = result.Sample_Date;
                                rObj["Analysis Date"] = result.Analysis_Date;
                                rObj["Collection Type"] =
                                  result.Sample_Collection_Type;
                                rObj["Tap"] = result.Tap;
                                rObj["Result"] = result.Result;
                                rObj["Initial_Lab_ID"] = result.Initial_Lab_ID;
                                rObj["First_Draw_Lab_ID"] =
                                  result.First_Draw_Lab_ID;
                                rObj["Initial First Draw Sampling Year"] =
                                  result.Initial_Sampling_Year;
                                return rObj;
                              })}
                              filename={
                                `${this.state.results[0].School_Name} ${this.state.results[0].Town_For_Sample} ${this.state.round === 0 ? "All" : this.state.round + " Sampling"} Lead Sample Results.csv`
                              }
                              target="_blank"
                            >

                              <i className="fas fa-file-csv"
                                alt="Download as CSV"
                                style={{ cursor: "pointer", fontSize: '1.75rem', color: '#1d6f42' }}></i>{' '}
                            </CSVLink>
                            <ExcelFile
                              element={
                                <i className="fas fa-file-excel" alt="Download as Excel"
                                  style={{ cursor: "pointer", fontSize: '1.75rem', color: '#1d6f42' }}></i>
                              }
                              filename={`${this.state.results[0].School_Name} ${this.state.results[0].Town_For_Sample} ${this.state.round === 0 ? "All" : this.state.round + " Sampling"} Lead Sample Results`}
                            >
                              <ExcelSheet
                                dataSet={this.getDownloadDataResults()}
                                name="Data"
                              />
                            </ExcelFile>
                          </span>
                          <br />
                          <br />
                          <div className="table-wrapper">
                            <Table className="samples-table">
                              <thead>
                                <tr>
                                  <th
                                    className="sortable-header"
                                    onClick={() => this.onSort("Lab_ID")}
                                  >
                                    Lab ID{" "}
                                    {this.renderSort(
                                      this.state.Lab_ID_Sort,
                                      "Lab_ID",
                                      this.state.Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() =>
                                      this.onSort("Collection_Date")
                                    }
                                  >
                                    Date Sampled{" "}
                                    {this.renderSort(
                                      this.state.Collection_Date_Sort,
                                      "Collection_Date",
                                      this.state.Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() => this.onSort("Analysis_Date")}
                                  >
                                    Date Analyzed{" "}
                                    {this.renderSort(
                                      this.state.Analysis_Date_Sort,
                                      "Analysis_Date",
                                      this.state.Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() =>
                                      this.onSort("Collection_Type")
                                    }
                                  >
                                    Collection Type{" "}
                                    {this.renderSort(
                                      this.state.Collection_Type_Sort,
                                      "Collection_Type",
                                      this.state.Sort
                                    )}
                                  </th>
                                  <th
                                    className="sortable-header"
                                    onClick={() => this.onSort("Tap")}
                                  >
                                    Tap{" "}
                                    {this.renderSort(
                                      this.state.Tap_Sort,
                                      "Tap",
                                      this.state.Sort
                                    )}
                                  </th>
                                  {/* <th>Building</th>
                          <th>Location</th>
                          <th>Fixture</th> */}
                                  <th
                                    className="sortable-header"
                                    onClick={() => this.onSort("Result")}
                                  >
                                    Result{" "}
                                    {this.renderSort(
                                      this.state.Result_Sort,
                                      "Result",
                                      this.state.Sort
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.results.map(
                                  result =>
                                    result.Lab_ID && (
                                      <tr key={result.ID}>
                                        <td
                                          style={{
                                            color:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? "#c64a37"
                                                : "#212528",
                                            fontWeight:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? 700
                                                : 500
                                          }}
                                        >
                                          {/* <span
                                  className="school-name"
                                  onClick={() => console.log()}
                                > */}
                                          {result.Lab_ID}
                                          {/* </span> */}
                                        </td>
                                        <td
                                          style={{
                                            color:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? "#c64a37"
                                                : "#212528",
                                            fontWeight:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? 700
                                                : 500
                                          }}
                                        >
                                          {result.Sample_Date}
                                        </td>
                                        <td
                                          style={{
                                            color:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? "#c64a37"
                                                : "#212528",
                                            fontWeight:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? 700
                                                : 500
                                          }}
                                        >
                                          {result.Analysis_Date}
                                        </td>
                                        <td
                                          style={{
                                            color:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? "#c64a37"
                                                : "#212528",
                                            fontWeight:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? 700
                                                : 500
                                          }}
                                        >
                                          {result.Sample_Collection_Type}
                                        </td>
                                        <td
                                          style={{
                                            color:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? "#c64a37"
                                                : "#212528",
                                            fontWeight:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? 700
                                                : 500
                                          }}
                                        >
                                          {result.Tap}
                                        </td>
                                        {/* <td>{result.Building}</td>
                                <td>{result.Fixture_Location}</td>
                                <td>{result.Fixture_Type}</td> */}
                                        <td
                                          style={{
                                            color:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? "#c64a37"
                                                : "#212528",
                                            fontWeight:
                                              result.Raw_Result &&
                                                result.Raw_Result >= actionLevel
                                                ? 700
                                                : 500,
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {result.Raw_Result &&
                                            result.Raw_Result >= actionLevel
                                            ? result.Result + "*"
                                            : result.Result}
                                        </td>
                                      </tr>
                                    )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </span>
                      )}
                    {/* {this.state.results && this.state.remediation.length > 0 && (
                <span>
                  <br />
                  <br />
                  <br />
                  <span>
                    <span className="h5">
                      All Remediation Actions for{" "}
                      {this.state.results[0].School_Name},{" "}
                      {this.state.results[0].Town_For_Sample}
                    </span>
                  <br />
                  <br />
                  <Table className="remediation-table">
                    <thead>
                      <tr>
                        <th>Tap</th>
                        <th>Action Taken</th>
                        <th>Date Action Taken</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.remediation.map(
                        action =>
                          action.Tap && (
                            <tr key={action.ID}>
                              <td>
                                  {action.Tap}
                              </td>
                              <td>{result.Sample_Date}</td>
                              <td>
                                {result.Sample_Collection_Type}
                              </td>
                              <td>{result.Tap}</td>
                              <td
                                style={{
                                  color:
                                    result.Raw_Result &&
                                    result.Raw_Result >= actionLevel
                                      ? "#c64a37"
                                      : "#212528"
                                }}
                              >
                                {result.Result}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                </span>
              )} */}
                  </span>
                )}
            </Col>
          </Row>
        </Container>
      </span >
    );
  }
}

export default School;
