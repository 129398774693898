import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { CSVLink } from "react-csv";
import {
  Alert,
} from "reactstrap";
import ReactTable from "react-table";
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from "recharts";
import { extent as d3Extent } from "d3-array";
import { scaleTime as d3ScaleTime } from "d3-scale";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Reports extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.renderTownChart = this.renderTownChart.bind(this);
    this.renderTimeChart = this.renderTimeChart.bind(this);

    this.state = {
      data: {},
      report: "",
      error: "",
      message: "",
      showMessageAlert: false,
      showErrorAlert: false,
      isTest: false,
      isInternal: false,
      loading: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.report !== this.props.match.params.report) {
      this.setState({
        data: {},
        report: "",
        error: "",
        message: "",
        showMessageAlert: false,
        showErrorAlert: false,
        isTest: false,
        isInternal: false
      });
      this.fetchData();
    }
  }

  handleSelect(School_ID) {
    // if (this.props.match.params.report === "1") {
    //   this.props.history.push({ pathname: "/testschool/" + School_ID });
    // } else {
    this.props.history.push({ pathname: "/internalschool/" + School_ID });
    // }
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  fetchData(event) {
    let url, useCredentials;

    this.setState({ loading: true });
    url = `${base_URL}/api/internal/reports`;
    useCredentials = "include";

    const report = this.props.match.params.report;

    // console.log(url);
    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        report: report,
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (!json || json.length === 0) {
          let message;
          message = "No Data Found";
          this.setState({
            message: message,
            showMessageAlert: true,
            data: {},
            loading: false
          });

          return;
        }
        var old = JSON.stringify(json).replaceAll('T00:00:00', ''); //convert to JSON string
        var newArray = JSON.parse(old); //convert back to array

        this.setState({ data: newArray, loading: false });
      })
      .catch(error => {
        this.setState({
          error: error.message,
          showErrorAlert: true,
          data: {},
          loading: false
        });
      });
  }

  getColumns() {
    if (
      this.props.match.params.report === "1" ||
      this.props.match.params.report === "2"
    ) {
      const columns = [
        {
          Header: "School",
          accessor: "School_Name",
          getProps: (state, rowInfo, column) => {
            return {
              onClick: () => this.handleSelect(rowInfo.original.School_ID),
              className: "report-school-name"
            };
          }
        },
        {
          Header: "Taps Unaddressed",
          accessor: "Taps_Unaddressed"
        },
        {
          Header: "First Exceedance",
          accessor: "First_Exceedance_Date"
        }
      ];
      return columns;
    }
    return Object.keys(this.state.data[0]).map(key => {
      return {
        Header: key,
        accessor: key
      };
    });
  }

  getTitle() {
    switch (this.props.match.params.report) {
      // case "1":
      //   return <span className="h4">Unaddressed Taps Test</span>;
      case "2":
        return <span className="h4">Unaddressed Taps</span>;
      // case "3":
      //   return <span className="h4">Data Export Test</span>;
      case "4":
        return <span className="h4">Data Export</span>;
      // case "5":
      //   return <span className="h4">Dashboard Test</span>;
      case "6":
        return <span className="h4">Dashboard</span>;
      // case "7":
      //   return <span className="h4">Remediation Export Test</span>;
      case "8":
        return <span className="h4">Remediation Export</span>;
      case "9":
        return <span className="h4">Tap Data Export</span>;
      case "10":
        return <span className="h4">Places with All Elevated Taps</span>;
      case "11":
        return <span className="h4">Results Missing Initial Lab ID</span>;
      case "12":
        return <span className="h4">Places with Unresolved Exceedances</span>;
      case "13":
        return <span className="h4">Providers with samples over time</span>;
      case "14":
        return <span className="h4">Exceedance Status Report</span>;
      case "15":
        return <span className="h4">Flush Samples without a Valid First Draw Lab ID</span>;
      default:
        return <span className="h4">Report</span>;
    }
  }

  renderTownChart() {
    if (!this.state.loading) {
      const data = this.state.data;

      var counts = data.reduce((p, c) => {
        var name = c.Town_For_Sample;
        if (!p.hasOwnProperty(name)) {
          p[name] = 0;
        }
        p[name]++;
        return p;
      }, {});

      var countsExtended = Object.keys(counts).map(k => {
        return { Town: k, Samples: counts[k] };
      });

      countsExtended.sort((b, a) => a["Samples"] - b["Samples"]);

      return (
        // <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={1100}
          height={100 + countsExtended.length * 50}
          data={countsExtended}
          layout="vertical"
          margin={{
            top: 5,
            right: 30,
            left: 100,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey="Town" />
          <Tooltip />
          <Legend />
          <Bar dataKey="Samples" fill="#8884d8" />
        </BarChart>
        // </ResponsiveContainer>
      );
    }
  }

  renderTimeChart() {
    if (!this.state.loading) {
      const data = this.state.data;

      var counts = data.reduce((p, c) => {
        var name = c.Sample_Month;
        if (!p.hasOwnProperty(name)) {
          p[name] = 0;
        }
        p[name]++;
        return p;
      }, {});

      var countsExtended = Object.keys(counts).map(k => {
        return {
          Date: k,
          DateNumber: new Date(k).getTime(),
          Samples: counts[k]
        };
      });

      countsExtended.sort((a, b) => a["DateNumber"] - b["DateNumber"]);

      const domain = d3Extent(countsExtended, d => new Date(d.Date));
      const tScale = d3ScaleTime()
        .domain(domain)
        .range([0, 1100]);

      const tickFormat = tScale.tickFormat();
      const ticks = tScale.ticks();

      return (
        <LineChart
          width={1100}
          height={200}
          data={countsExtended}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="DateNumber"
            scale="time"
            type="number"
            domain={domain}
            ticks={ticks}
            tickFormatter={tickFormat}
          />
          <YAxis />
          <Tooltip
            labelFormatter={label => {
              const date = new Date(label);
              const formatted = date.toLocaleString("en-us", {
                month: "long",
                year: "numeric"
              });
              return formatted;
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="Samples"
            stroke="#8884d8"
            activeDot={{ r: 5 }}
          />
        </LineChart>
      );
    }
  }

  render() {
    return (
      <span>
        {this.state.data && (
          <Container style={{ textAlign: "center" }}>
            <Row>
              <Col>
                {this.getTitle()}{" "}
                {this.state.data.length > 0 && (
                  <CSVLink
                    data={this.state.data}
                    filename={"Report Data.csv"}
                    target="_blank"
                  >
                    <i className="fas fa-file-csv"
                      alt="Download as CSV"
                      style={{ cursor: "pointer", fontSize: '1.75rem', color: '#1d6f42' }}></i>{' '}
                  </CSVLink>
                )}
                <br />
                <br />
                <span className="login-spinner">
                  <BounceLoader
                    size={60}
                    sizeUnit={"px"}
                    color={"#36D7B7"}
                    css={override}
                    loading={this.state.loading}
                  />
                </span>
                {this.props.match.params.report == "9" && this.state.loading && (
                  <>
                    <div>This may take a couple minutes...</div>
                    <div>Calculating tap-level details for thousands of taps.</div>
                  </>
                )}
                {this.state.error !== "" && (
                  <Alert
                    color="warning"
                    isOpen={this.state.showErrorAlert}
                    toggle={this.toggleErrorAlert}
                  >
                    {this.state.error}
                  </Alert>
                )}
                {this.state.message !== "" && (
                  <Alert
                    color="info"
                    isOpen={this.state.showMessageAlert}
                    toggle={this.toggleMessageAlert}
                  >
                    {this.state.message}
                  </Alert>
                )}
                {(this.props.match.params.report === "5" ||
                  this.props.match.params.report === "6") &&
                  this.state.data &&
                  this.state.data.length > 0 &&
                  this.state.data[0].Town_For_Sample && (
                    <span>
                      <h5>Samples by Collection Date over Time</h5>
                      <br />
                      {this.renderTimeChart()}
                      <br />
                      <h5>Samples by Town</h5>
                      <br />
                      {this.renderTownChart()}
                    </span>
                  )}
                {this.state.data.length > 0 &&
                  (this.props.match.params.report !== "5" &&
                    this.props.match.params.report !== "6") && (
                    <Col>
                      <span>
                        {this.props.match.params.report === "13" && (
                          <>
                            <div>Given the delay between samples taken and results entered into CDMS the more recent months may not be accurate for Facilities Sampled or Facilities with Samples Analyzed – as data that haven’t yet been uploaded would not be included.</div>
                            <br />
                          </>
                        )}
                        <ReactTable
                          data={this.state.data}
                          columns={this.getColumns()}
                          // showPagination={false}
                          minRows={1}
                          className="-striped -highlight"
                        />
                        {/* <Table className="search-table">
                        <thead>
                          <tr>
                            <th>School</th>
                            <th>Taps Unaddressed</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map(
                            school =>
                              school.School_Name && (
                                <tr key={school.School_Name}>
                                  <td>
                                    <span
                                      className="school-name"
                                      onClick={() =>
                                        this.handleSelect(school.School_ID)
                                      }
                                    >
                                      {school.School_Name}
                                    </span>
                                  </td>
                                  <td>{school.Taps_Unaddressed}</td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </Table> */}
                      </span>
                    </Col>
                  )}
              </Col>
            </Row>
          </Container>
        )}
      </span>
    );
  }
}

export default Reports;
