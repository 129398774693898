import React, { Component } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import { Button, Input, Alert } from "reactstrap";
import Papa from "papaparse";
import { base_URL } from "../config/buildSettings.js";
import { apiKey, actionLevel } from "../config/index.js";

class Upload extends Component {
  constructor(props) {
    super(props);

    this.handlePreview = this.handlePreview.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleSelectedFile = this.handleSelectedFile.bind(this);
    this.updateData = this.updateData.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.renderStatus = this.renderStatus.bind(this);

    this.state = {
      selectedFile: null,
      error: "",
      message: "",
      results: {},
      showMessageAlert: false,
      showErrorAlert: false
    };
  }

  handleSelectedFile(event) {
    event.preventDefault();
    let file = event.target.files[0];
    // console.log(file);
    this.setState({
      selectedFile: file
    });
  }

  handlePreview = e => {
    e.preventDefault();
    if (this.state.selectedFile == null) {
      this.setState({ message: "No File Selected", showMessageAlert: true });
      return;
    }
    Papa.parse(this.state.selectedFile, {
      complete: this.updateData,
      header: true
    });
  };

  async handleUpload(e) {
    e.preventDefault();

    if (this.state.results.length === undefined) {
      this.setState({
        message: "Preview results first",
        showMessageAlert: true
      });
      return;
    }
    this.setState({ message: "", showMessageAlert: false });

    let url;

    // Use credentials if test
    if (this.props.location.pathname.search("test") !== -1) {
      url = `${base_URL}/api/internal/uploadtest`;
    } else {
      url = `${base_URL}/api/internal/upload`;
    }

    // console.log(url);

    this.setState(prevState => {
      const results = prevState.results;
      results.forEach(result => {
        result.status = "ready";
        result.response = "";
      });
      return {
        results
      };
    });

    let results = this.state.results;
    let index;

    for (index = 0; index < results.length; index++) {
      let result = results[index];
      this.setState(prevState => {
        const results = prevState.results;
        results[index].status = "sending";
        return {
          results
        };
      });

      await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          result,
          apiKey: apiKey
        })
      })
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response;
        })
        .then(response => response.json())
        .then(json => {
          if (json !== "Success") {
            this.setState(prevState => {
              const results = prevState.results;
              results[index].status = "error";
              results[index].response = json;
              return {
                results
              };
            });
            return;
          } else {
            this.setState(prevState => {
              const results = prevState.results;
              results[index].status = "success";
              results[index].response = json;
              return {
                results
              };
            });
            return;
          }
        })
        .catch(error => {
          this.setState({ error: error.message, showErrorAlert: true });
        });
    }

  }

  handleSimulate = e => {
    e.preventDefault();
    if (this.state.results.length === undefined) {
      this.setState({
        message: "Preview results first",
        showMessageAlert: true
      });
      return;
    }
    this.setState({ message: "", showMessageAlert: false });

    let url;

    if (this.props.location.pathname.search("test") !== -1) {
      url = `${base_URL}/api/internal/simulateuploadtest`;
    } else {
      url = `${base_URL}/api/internal/simulateupload`;
    }

    this.setState(prevState => {
      const results = prevState.results;
      results.forEach(result => {
        result.status = "ready";
        result.response = "";
      });
      return {
        results
      };
    });

    let results = this.state.results;
    //results = results.slice(0, 1);
    results.map((result, index) => {
      // console.log(result);
      this.setState(prevState => {
        const results = prevState.results;
        results[index].status = "sending";
        return {
          results
        };
      });

      (async () => {
        await fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            result,
            apiKey: apiKey
          })
        })
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response;
          })
          .then(response => response.json())
          .then(json => {
            if (json !== "Success") {
              this.setState(prevState => {
                const results = prevState.results;
                results[index].status = "error";
                results[index].response = json;
                return {
                  results
                };
              });
              return;
            } else {
              this.setState(prevState => {
                const results = prevState.results;
                results[index].status = "success";
                results[index].response = json;
                return {
                  results
                };
              });
              return;
            }
          })
          .catch(error => {
            this.setState({ error: error.message, showErrorAlert: true });
          });
      })();
      // console.log(Date.now());
    });
  };

  updateData(results) {
    // console.log(results.data);
    results.data = results.data.filter(
      result => result.Lab_ID && result.Lab_ID !== ""
    );
    // console.log(results.data);

    results.data.forEach((result, i) => {
      result.ID = i + 1;
      result.status = "";
      result.response = "";
      if (result.Kit_Type === "KIT DC - Lead" && result.Provider_Name) {
        result.School_Name = result.Provider_Name;
      }
    });

    const grouped = [
      ...results.data
        .reduce((r, o) => {
          const key = o.Lab_ID;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              Contact_Name: o.Contact_Name,
              Contact_Email: o.Contact_Email,
              rows: 0
            });

          if (
            item.rows > 0 &&
            item.Contact_Email &&
            item.Contact_Email !== ""
          ) {
            item.Contact_Name = item.Contact_Name + ", " + o.Contact_Name;
            item.Contact_Email = item.Contact_Email + ", " + o.Contact_Email;
          }

          item.rows += 1;

          return r.set(key, item);
        }, new Map())
        .values()
    ]

    // .sort((a, b) => {
    //   const sampleA = a.Sample_Type.toLowerCase();
    //   const sampleB = b.Sample_Type.toLowerCase();
    //   const typeA = a.Collection_Type.toLowerCase();
    //   const typeB = b.Collection_Type.toLowerCase();

    //   if (sampleA === 'initial' && sampleB !== 'initial') {
    //     return -1;
    //   } else if (sampleB === 'initial' && sampleA !== 'initial') {
    //     return 1;
    //   } else if (typeA === 'first draw' && typeB !== 'first draw') {
    //     return -1;
    //   } else if (typeB === 'first draw' && typeA !== 'first draw') {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // })


    this.setState({
      results: grouped
    });
    if (this.state.results.length === 0) {
      this.setState({
        message: "No valid results found in file",
        showMessageAlert: true
      });
      return;
    }
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  renderStatus(status) {
    switch (status) {
      case "ready":
        return (
          <i className="fas fa-hourglass-start" style={{ fontSize: "150%" }} />
        );
      case "sending":
        return (
          <i className="far fa-paper-plane" style={{ fontSize: "150%" }} />
        );
      case "success":
        return (
          <i
            className="far fa-check-circle"
            style={{ color: "#26d62c", fontSize: "150%" }}
          />
        );
      case "error":
        return (
          <i
            className="far fa-times-circle"
            style={{ color: "#d60023", fontSize: "150%" }}
          />
        );
      default:
        return <span />;
    }
  }

  render() {
    return (
      <span>
        <Container style={{ textAlign: "center" }}>
          <Row>
            <Col md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }}>
              <h4>
                {this.props.location.pathname.search("test") !== -1
                  ? "Test File Upload"
                  : "File Upload"}
              </h4>
              <br />
              <Input
                type="file"
                name="file"
                accept=".csv"
                id="fileInput"
                aria-label="File Input"
                ref={input => {
                  this.filesInput = input;
                }}
                onChange={this.handleSelectedFile}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              style={{ display: "inherit" }}
              md={{ size: 6, offset: 3 }}
              lg={{ size: 6, offset: 3 }}
            >
              <Button
                // style={{ display: "inherit" }}
                type="submit"
                color="primary"
                onClick={this.handlePreview}
                aria-label="Preview"
              >
                Preview
              </Button>
              &nbsp;
              <Button
                // style={{ display: "inherit" }}
                type="submit"
                color="primary"
                onClick={this.handleSimulate}
                aria-label="Simulate"
              >
                Simulate
              </Button>
              &nbsp;
              <Button
                // style={{ display: "inherit" }}
                type="submit"
                color="primary"
                onClick={this.handleUpload}
                aria-label="Upload"
              >
                Upload
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }}>
              {this.state.error !== "" && (
                <Alert
                  color="warning"
                  isOpen={this.state.showErrorAlert}
                  toggle={this.toggleErrorAlert}
                >
                  {this.state.error}
                </Alert>
              )}
              {this.state.message !== "" && (
                <Alert
                  color="info"
                  isOpen={this.state.showMessageAlert}
                  toggle={this.toggleMessageAlert}
                >
                  {this.state.message}
                </Alert>
              )}
            </Col>
          </Row>
          {/* Error Table */}
          {this.state.results.length > 0 &&
            this.state.results.filter(result => result.status === "error")
              .length > 0 && (
              <span>
                <Row>
                  <Col>
                    <h5>Upload Errors</h5>
                    <br />
                    <Table className="samples-table">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Lab ID</th>
                          <th>Tap ID</th>
                          <th>Report Type</th>
                          <th>Provider</th>
                          <th>Collection Date</th>
                          <th>Sample Type</th>
                          <th>Location</th>
                          <th>Fixture</th>
                          <th>Result</th>
                          <th>Contact Email</th>
                          <th>Response</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.results
                          .filter(result => result.status === "error")
                          .map(
                            (result, index) =>
                              result.Lab_ID &&
                              result.Lab_ID !== "" && (
                                <tr key={index}>
                                  <td className="upload-status">
                                    {this.renderStatus(result.status)}
                                  </td>
                                  <td>
                                    <span
                                      className="school-name"
                                      onClick={() => console.log()}
                                    >
                                      {result.Lab_ID}
                                    </span>
                                  </td>
                                  <td>{result.Tap_ID}</td>
                                  <td>{result.Report_Status}</td>
                                  <td>{result.School_Name}</td>
                                  <td>{result.Sample_Date}</td>
                                  <td>{result.Sample_Type.concat(', ', result.Collection_Type)}</td>
                                  <td>{result.Fixture_Location}</td>
                                  <td>{result.Fixture_Type}</td>
                                  <td
                                    style={{
                                      color:
                                        result.Result &&
                                          result.Result >= actionLevel
                                          ? "#c64a37"
                                          : "#212528"
                                    }}
                                  >
                                    {result.Result != "NULL"
                                      ? result.Result + " " + result.Units
                                      : "<0.001 mg/L"}
                                  </td>
                                  <td>{result.Contact_Email}</td>
                                  <td>{result.response}</td>
                                </tr>
                              )
                          )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </span>
            )}
          {this.state.results.length > 0 && (
            <span>
              <Row>
                <Col>
                  <h5>All Samples</h5>
                  <br />
                  <Table className="samples-table">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Lab ID</th>
                        <th>Tap ID</th>
                        <th>Report Type</th>
                        <th>Provider</th>
                        <th>Collection Date</th>
                        <th>Sample Type</th>
                        <th>Location</th>
                        <th>Fixture</th>
                        <th>Result</th>
                        <th>Contact Email</th>
                        <th>Response</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.results.map(
                        (result, index) =>
                          result.Lab_ID &&
                          result.Lab_ID !== "" && (
                            <tr key={index}>
                              <td className="upload-status">
                                {this.renderStatus(result.status)}
                              </td>
                              <td>
                                <span
                                  className="school-name"
                                  onClick={() => console.log()}
                                >
                                  {result.Lab_ID}
                                </span>
                              </td>
                              <td>{result.Tap_ID}</td>
                              <td>{result.Report_Status}</td>
                              <td>{result.School_Name}</td>
                              <td>{result.Sample_Date}</td>
                              <td>{result.Sample_Type.concat(', ', result.Collection_Type)}</td>
                              <td>{result.Fixture_Location}</td>
                              <td>{result.Fixture_Type}</td>
                              <td
                                style={{
                                  color:
                                    result.Result &&
                                      result.Result >= actionLevel
                                      ? "#c64a37"
                                      : "#212528"
                                }}
                              >
                                {result.Result != "NULL"
                                  ? result.Result + " " + result.Units
                                  : "<0.001 mg/L"}
                              </td>
                              <td>{result.Contact_Email}</td>
                              <td>{result.response}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </span>
          )}
        </Container>
      </span>
    );
  }
}

export default Upload;
