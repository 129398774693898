import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import { Button, Form, Input, Alert, Label, Table } from "reactstrap";
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AdminResults extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.handleInactivateClick = this.handleInactivateClick.bind(this);
    this.inactivateResult = this.inactivateResult.bind(this);

    this.state = {
      isAuthenticated: false,
      Lab_ID: '',
      isTest: true,
      loading:true,
      warn:false,
      error: "",
      message: "",
      showMessageAlert: false,
      showErrorAlert: false
    };
  }

  componentDidMount() {
    if(this.props.location.pathname.search("test")=== -1) {
      this.setState({ isTest: false});
    }
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname.search("test") !== -1 && this.state.isTest ) {
      this.setState({isTest: false}); 
    } else if (this.props.location.pathname.search("test") === -1 && !this.state.isTest ) {
      this.setState({isTest: true}); 
    }
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleInactivateClick() {
    this.setState({warn: true});
  }
  
  inactivateResult() {
    let url;

    if (this.props.location.pathname.search("test") !== -1) {
      this.setState({ isTest: true });
      url = `${base_URL}/api/internal/inactivateresulttest`;
    } else {
      this.setState({ isTest: false });
      url = `${base_URL}/api/internal/inactivateresult`;
    } 

    console.log(url);

    fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Lab_ID: this.state.Lab_ID,
        comments: this.state.comments,
        apiKey: apiKey
      })
    })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        this.setState({
          message: json,
          showMessageAlert: true,
          warn: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error: error.message, showErrorAlert: true, warn: false });
      });
  }

  render() {
    return (
      <Container style={{ textAlign: "center" }}>
        <Row>
          <Col>
            <br />
            <h4>Inactivate Results</h4>
            <br />
            {/* <span className="login-spinner">
              <BounceLoader
                size={60}
                sizeUnit={"px"}
                color={"#36D7B7"}
                css={override}
                loading={this.state.loading}
              />
            </span> */}
            {this.state.error !== "" && (
              <Col md={{ size: 6, offset: 3 }}>
                <Alert
                  color="warning"
                  isOpen={this.state.showErrorAlert}
                  toggle={this.toggleErrorAlert}
                >
                  {this.state.error}
                </Alert>
              </Col>
            )}
            {this.state.message !== "" && (
              <Col md={{ size: 6, offset: 3 }}>
                <Alert
                  color="info"
                  isOpen={this.state.showMessageAlert}
                  toggle={this.toggleMessageAlert}
                >
                  {this.state.message}
                </Alert>
              </Col>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 4, offset: 4 }} style={{ marginBottom: "1rem" }}>
            <Label for="labIdInput">Lab ID to Inactivate</Label>
            <Input
              type="text"
              name="Lab_ID"
              id="labIdInput"
              onChange={this.handleInputChange}
              placeholder="Lab ID"
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 6, offset: 3 }} style={{ marginBottom: "1rem" }}>
            <Label for="commentsInput">Inactivation Comments</Label>
            <Input
              type="textarea"
              name="comments"
              id="commentsInput"
              onChange={this.handleInputChange}
              placeholder="Comments"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={this.handleInactivateClick} color="primary">
              Inactivate
            </Button>
            {this.state.warn && (
              <span>
                {"  "}
                <Button onClick={this.inactivateResult} color="danger">
                  Confirm
                </Button>
              </span>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AdminResults;
