import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  Alert,
  Button
} from "reactstrap";
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import XLSX from "xlsx";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Export extends Component {
  constructor(props) {
    super(props);

    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);

    this.handleExportClicked = this.handleExportClicked.bind(this);

    this.state = {
      data: {},
      error: "",
      message: "",
      showMessageAlert: false,
      showErrorAlert: false,
      loading: false,
      clicked: "",
      status: ""
    };
  }


  handleExportClicked(clicked) {
    let url, useCredentials;
    this.setState({ loading: true, status: `Downloading ${clicked}...` });
    url = `${base_URL}/api/internal/getexport${clicked}`;
    useCredentials = "include";

    // console.log(url);
    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        apiKey: apiKey
      })
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => {
        this.setState({ status: `Preparing Excel File of ${clicked}...` });

        return response.json()
      })
      .then(json => {
        if (!json || json.length === 0) {
          let message;
          message = "No Data Found";
          this.setState({
            message: message,
            showMessageAlert: true,
            data: {},
            loading: false
          });

          return;
        }
        var old = JSON.stringify(json).replaceAll('T00:00:00', ''); //convert to JSON string
        var newArray = JSON.parse(old); //convert back to array

        const today = new Date().toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "2-digit" }).split("/").join("-");

        const worksheet = XLSX.utils.json_to_sheet(newArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `${clicked}`);
        XLSX.writeFile(workbook, `CDMS ${clicked} data ${today}.xlsx`, {compression:true});

        this.setState({ loading: false, status: `Successfully downloaded ${clicked}...` })
      })
      .catch(error => {
        this.setState({
          error: error.message,
          showErrorAlert: true,
          data: {},
          loading: false
        });
      });
  }

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: ""
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: ""
    });
  }

  render() {
    return (
      <span>
        <Container style={{ textAlign: "center" }}>
          <Row>
            <Col>
              {<span className="h4">Export Data</span>}
              <br />
              <br />
              {this.state.error !== "" && (
                <Alert
                  color="warning"
                  isOpen={this.state.showErrorAlert}
                  toggle={this.toggleErrorAlert}
                >
                  {this.state.error}
                </Alert>
              )}
              {this.state.message !== "" && (
                <Alert
                  color="info"
                  isOpen={this.state.showMessageAlert}
                  toggle={this.toggleMessageAlert}
                >
                  {this.state.message}
                </Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col>

              <Button
                color="info"
                onClick={() => {
                  this.setState({ clicked: "facilities" });
                  this.handleExportClicked("facilities");
                }}
                active={this.state.clicked === "facilities"}
                disabled={this.state.loading && this.state.clicked !== "facilities"}
              >
                Facilities
              </Button>
              {` `}

              <Button
                color="info"
                onClick={() => {
                  this.setState({ clicked: "results" });
                  this.handleExportClicked("results");
                }}
                active={this.state.clicked === "results"}
                disabled={this.state.loading && this.state.clicked !== "results"}
              >
                Results
              </Button>
              {` `}
              <Button
                color="info"
                onClick={() => {
                  this.setState({ clicked: "remediations" });
                  this.handleExportClicked("remediations");
                }}
                active={this.state.clicked === "remediations"}
                disabled={this.state.loading && this.state.clicked !== "remediations"}
              >
                Remediations
              </Button>
              {` `}
              <Button
                color="info"
                onClick={() => {
                  this.setState({ clicked: "facilityusers" });
                  this.handleExportClicked("facilityusers");
                }}
                active={this.state.clicked === "facilityusers"}
                disabled={this.state.loading && this.state.clicked !== "facilityusers"}
              >
                Facility Users
              </Button>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <span className="login-spinner">
                <BounceLoader
                  size={60}
                  sizeUnit={"px"}
                  color={"#36D7B7"}
                  css={override}
                  loading={this.state.loading}
                />
              </span>
            </Col>
          </Row>
          <br />
          <br />
          {this.state.status !== "" && (
            <Row>
              <Col>
                <span>{this.state.status}</span>
              </Col>
            </Row>
          )}
        </Container>
      </span>
    );
  }
}

export default Export;
