let base_URL;
if (process.env.NODE_ENV !== "production") {
  base_URL = "http://localhost:61932";
} else {
  if (
    window.location.href
      .toLowerCase()
      .search("dotnetv4.vtanr.state.vt.us/leadinschools") !== -1
  ) {
    //testweb
    base_URL = "http://dotnetv4.vtanr.state.vt.us/leadinschools";
  } else if (
    window.location.href
      .toLowerCase()
      .search("anrweb.vt.gov/dec/leadinschoolsreports") !== -1
  ) {
    //report
    base_URL = "https://anrweb.vt.gov/DEC/LeadInSchoolsReports";
  } else if (
    window.location.href
      .toLowerCase()
      .search("anrweb.vt.gov/dec/leadinschools") !== -1
  ) {
    //anrweb
    base_URL = "https://anrweb.vt.gov/DEC/leadinschools";
  } else if (
    window.location.href.toLowerCase().search("www.leadresults.vermont.gov") !==
    -1
  ) {
    //anrweb
    base_URL = "http://www.leadresults.vermont.gov";
  } else if (
    window.location.href
      .toLowerCase()
      .search("http://leadresults.vermont.gov") !== -1
  ) {
    //anrweb
    base_URL = "http://leadresults.vermont.gov";
  } else if (
    window.location.href
      .toLowerCase()
      .search("http://www.leadresults.vt.gov") !== -1
  ) {
    //anrweb
    base_URL = "http://www.leadresults.vt.gov";
  } else if (
    window.location.href.toLowerCase().search("http://leadresults.vt.gov") !==
    -1
  ) {
    //anrweb
    base_URL = "http://leadresults.vt.gov";
  } else if (
    window.location.href
      .toLowerCase()
      .search("https://leadresults.vermont.gov") !== -1
  ) {
    //anrweb
    base_URL = "https://leadresults.vermont.gov";
  } else if (
    window.location.href
      .toLowerCase()
      .search("https://leadresultstest.vermont.gov") !== -1
  ) {
    //anrweb
    base_URL = "https://leadresultstest.vermont.gov";
  } else if (
    window.location.href
      .toLowerCase()
      .search("http://leadresultstest.vermont.gov") !== -1
  ) {
    //anrweb
    base_URL = "http://leadresultstest.vermont.gov";
  } else if (
    window.location.href
      .toLowerCase()
      .search("https://www.leadresults.vt.gov") !== -1
  ) {
    //anrweb
    base_URL = "https://www.leadresults.vt.gov";
  } else if (
    window.location.href.toLowerCase().search("https://leadresults.vt.gov") !==
    -1
  ) {
    //anrweb
    base_URL = "https://leadresults.vt.gov";
  }
  else if (
    window.location.href
      .toLowerCase()
      .search("http://pubapps.vtanr.state.vt.us/dec/leadinschools") !== -1
  ) {
    //anrweb
    base_URL = "http://pubapps.vtanr.state.vt.us/DEC/leadinschools";
  }
  else if (
    window.location.href
      .toLowerCase()
      .search("http://anrwebtest.vt.gov/dec/leadinschools") !== -1
  ) {
    //anrweb
    base_URL = "http://anrwebtest.vt.gov/DEC/leadinschools";
  }
  else if (
    window.location.href
      .toLowerCase()
      .search("https://anrwebtest.vt.gov/dec/leadinschools") !== -1
  ) {
    //anrweb
    base_URL = "https://anrwebtest.vt.gov/DEC/leadinschools";
  }
  else if (
    window.location.href
      .toLowerCase()
      .search("https://pubapps.vtanr.state.vt.us/dec/leadinschools") !== -1
  ) {
    //anrweb
    base_URL = "https://pubapps.vtanr.state.vt.us/DEC/leadinschools";
  } else {
    //anrweb
    base_URL = "";
  }
}


let basename;

if (process.env.NODE_ENV !== "production") {
  basename = "/";
} else {
  if (
    window.location.href
      .toLowerCase()
      .search("dotnetv4.vtanr.state.vt.us/leadinschools") !== -1
  ) {
    //testweb
    basename = "/leadinschools";
  } else if (
    window.location.href
      .toLowerCase()
      .search("anrweb.vt.gov/dec/leadinschoolsreports") !== -1
  ) {
    //report
    basename = "/DEC/LeadInSchoolsReports";
  } else if (
    window.location.href
      .toLowerCase()
      .search("anrweb.vt.gov/dec/leadinschools") !== -1
  ) {
    //anrweb
    basename = "/DEC/leadinschools";
  } else if (
    window.location.href
      .toLowerCase()
      .search("anrwebtest.vt.gov/dec/leadinschools") !== -1
  ) {
    //anrweb
    basename = "/DEC/leadinschools";
  } else if (
    window.location.href.toLowerCase().search("leadresults.vermont.gov") !== -1
  ) {
    //anrweb
    basename = "/";
  } else if (
    window.location.href.toLowerCase().search("leadresultstest.vermont.gov") !== -1
  ) {
    //anrweb
    basename = "/";
  } else if (
    window.location.href.toLowerCase().search("leadresults.vt.gov") !== -1
  ) {
    //anrweb
    basename = "/";
  } else if (
    window.location.href
      .toLowerCase()
      .search("pubapps.vtanr.state.vt.us/dec/leadinschools") !== -1
  ) {
    //report
    basename = "/DEC/leadinschools";
  } else {
    //anrweb
    basename = "/";
  }
}

let credentials;

if (process.env.NODE_ENV !== "production") {
  credentials = "omit";
} else {
  credentials = "same-origin";
}

module.exports = { base_URL, credentials, basename };
