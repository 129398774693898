import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";
import { LoadingSpinner } from './LoadingSpinner.js';

export function EditSchoolModal(props) {
    const [loading, setLoading] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const [formerName, setFormerName] = useState("");
    const [town, setTown] = useState("");
    const [AOEID, setAOEID] = useState("");
    const [active, setActive] = useState(true);
    const [error, setError] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);


    useEffect(() => {
    }, []);

    useEffect(() => {
        if (props.school.School_ID) {
            console.log('school changed: ', props.school.School_ID);
            loadSchool();
        }
    }, [props.school.School_ID]);


    const toggleErrorAlert = () => {
        setShowErrorAlert(!showErrorAlert);
        setError("");
    }

    const handleCancel = (event) => {
        event.preventDefault();
        setError("");
        setShowErrorAlert(false);
        setSchoolName("");
        setFormerName("");
        setTown("");
        setAOEID("");
        setActive(1);
        props.cancel();
    }


    const handleSave = (event) => {
        event.preventDefault();
        saveSchool();
    }

    const loadSchool = () => {
        setSchoolName(props.school.School_Name);
        setFormerName(props.school.Former_Name);
        setTown(props.school.Town);
        setAOEID(props.school.AOE_ID);
        setActive(props.school.Active_Facility);
    }


    const saveSchool = () => {
        let url, useCredentials;

        let env = props.env;
        let suffix;

        if (env === 'prod') {
            suffix = 'schoolnamechange';
        }
        if (env === 'test') {
            suffix = 'schoolnamechangetest';
        }

        url = `${base_URL}/api/internal/${suffix}`;
        useCredentials = 'include';

        setLoading(true);

        console.log(url);
        fetch(url, {
            method: "POST",
            credentials: useCredentials,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ID: parseInt(props.school.School_ID),
                School_Name: schoolName,
                Town: town,
                Former_Name: formerName,
                AOE_ID: AOEID,
                apiKey: apiKey,
                Active_Facility: active
            })
        }).then(response => {
            console.log(response);
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.text();
        }).then(text => {
            console.log('text ', text);
            if (text === "\"Success\"") {
                setLoading(false);
                props.cancel()
            } else {
                throw Error(text);
            }
        }).catch(error => {
            console.error(error);
            if (!error.message) {
                error.message = "Error saving school";
            }
            setError(error.message);
            setShowErrorAlert(true);
            setLoading(false);
        });
    }


    return (
        <Modal isOpen={props.open} toggle={props.cancel}>
            <Form onSubmit={handleSave}>
                <ModalHeader>{props.env === 'test' ? `TEST ` : ``}{`Edit ${props.school.Type}`}</ModalHeader>
                <ModalBody>
                    {loading && (
                        <Container>
                            <Row>
                                <Col xs={{ size: 1, offset: 5 }}>
                                    <LoadingSpinner loading={loading} delay={750} />
                                </Col>
                            </Row>
                        </Container>
                    )}

                    {props.school.School_ID && (
                        <>
                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="schoolNameInput">School Name</Label>
                                        <Input
                                            type="text"
                                            name="schoolName"
                                            id="schoolNameInput"
                                            value={schoolName}
                                            maxLength={255}
                                            onChange={e => setSchoolName(e.target.value)}
                                            placeholder="School Name"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="formerNameInput">Former Name</Label>
                                        <Input
                                            type="text"
                                            name="formerName"
                                            id="formerNameInput"
                                            value={formerName}
                                            maxLength={255}
                                            onChange={e => setFormerName(e.target.value)}
                                            placeholder="Former Name"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="townInput">Town</Label>
                                        <Input
                                            type="text"
                                            name="town"
                                            id="townInput"
                                            value={town}
                                            maxLength={255}
                                            onChange={e => setTown(e.target.value)}
                                            placeholder="Town"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="AOEIDInput">AOE ID</Label>
                                        <Input
                                            type="text"
                                            name="AOEID"
                                            id="AOEIDInput"
                                            value={AOEID}
                                            maxLength={255}
                                            onChange={e => setAOEID(e.target.value)}
                                            placeholder="AOE ID"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <FormGroup>
                                        <Label for="activeInput">Facility Status</Label>
                                        <Input type="select"
                                            name="active" id="activeInput"
                                            value={active}
                                            onChange={e => setActive(e.target.value)}>
                                            <option key={1} value={true}>
                                                {`Active`}</option>
                                            <option key={0} value={false}>
                                                {`Inactive`}</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    )}
                    {error !== "" && (
                        <Row>
                            <Col>
                                <Alert
                                    color="warning"
                                    isOpen={showErrorAlert}
                                    toggle={toggleErrorAlert}
                                >
                                    {error}
                                </Alert>
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="primary" onClick={handleSave}>
                        Save
                    </Button>{` `}
                    <Button color="primary" onClick={handleCancel}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

